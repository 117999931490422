import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import { NavLink, useParams } from 'react-router-dom';
import { Button,  Icon, Popup} from 'semantic-ui-react';
import SKLT from '../../AssetsM/usedSlk';
import GConf from '../../AssetsM/generalConf';
import BreadCrumb from '../Assets/breadCrumb'
import { toast } from 'react-toastify';
import FrameForPrint from '../Assets/frameForPrint';
import usePrintFunction from '../Assets/Hooks/printFunction';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import TableGrid from '../Assets/tableGrid';
import { _ } from 'gridjs-react';
import { useNavigate} from 'react-router-dom';
import ADILAPI from '../../AssetsM/ADILAPI';

function FactureInfo() {
    /*#########################[Const]##################################*/
    const {genre} = useParams()
    const ComosedParamas = Object.keys(ADILAPI).find(key => ADILAPI[key].directoryTable === genre).split( '_' )[0];
    //const ComosedParamas = genre.split('_').slice(1).join('_');
    const [rendredData, setRendredData] = useState([])
    const [PieData, setPieData]= useState([])

    const [tableHeader, setTableHearderListe] = useState([])
    const [commandeData, setCommandeD] = useState([])
    const [facturerData, setFacturerD] = useState([])
    const [loading , setLoading] = useState(false)
    const [btnState, setBtnState] = useState(false)
    const navigate = useNavigate();

    /*#########################[useEffect]##################################*/ 
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/annuaire/info`, {
            PID : GConf.PID,
            tableName: genre
          })
          .then(function (response) {
            setRendredData(response.data)
            setTableHearderListe(Object.keys(response.data[0]))
            let factureListContainer = []
            response.data.map( (getData) => factureListContainer.push([
            _(getData.Activated ? <span className='bi bi-patch-check-fill text-success bi-md'></span>: <span className='bi bi-shield-slash-fill text-secondary bi-md'></span>),
            getData.PK,
            getData.PID,
            getData.Name,
            getData.Phone,
            getData.Gouv,
            getData.Deleg,
            _(<div className="text-truncate" style={{maxWidth: '150px'}}>{getData.Adress}</div>),
            _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/S/an/info/${ComosedParamas}/${getData.PID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
            ],))
            setCommandeD(factureListContainer)

            setLoading(true)  
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger la commande   </div></>, GConf.TostInternetGonf)   
              setLoading(true) 
            }
          });
    }, [])

    /*#########################[Functions]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const RetunTableFunc = ({ data }) => {
        // Get the column names from the object         
        if (data[0]) {
            const columnNames = Object.keys(data[0]);
            return (
                <table className='table table-bordered'>
                <thead>
                    <tr>
                    {columnNames.map(columnName => (
                        <th key={columnName}>{columnName}</th>
                    ))}
                    </tr>
                </thead>
                {/* <tbody>
                    {Object.values(data).map((rowData, index) => (
                    <tr key={index}>
                        {Object.values(rowData).map((cellData, cellIndex) => (
                        <td key={cellIndex}>{cellData}</td>
                        ))}
                    </tr>
                    ))}
                </tbody> */}
                </table>
            );
        } else {
               return(<>Pas de Donneé</>) 
        }
        
    }

    const FetchByGenre = (genre) =>{
        let found = rendredData.filter(element => element.State === genre)
        if (found) {
            return(found.length)
        } else {
            return 0
        }
    
 }

    const UpdateState = (stateBtn) =>{
        axios.post(`${GConf.ApiLink}/commande/controle`, {
            PID : GConf.PID,
            genre: genre,
            state: stateBtn
          })
          .then(function (response) {
            setCommandeD({ ...commandeData, State: stateBtn}) 
            setBtnState(true)            
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de modifier L'etat du commande  </div></>, GConf.TostInternetGonf)   
              setBtnState(true)
            }
          });
    }
    const FacturerCommande = () =>{
        axios.post(`${GConf.ApiLink}/facture/ajouter`, {
            PID : GConf.PID,
            factD: facturerData,
        })
        .then(function (response) { 
            if(response.status = 200) {
                UpdateState('A')
                toast.success("Done !", GConf.TostSuucessGonf)
                setBtnState(true)
            }
            else{
                toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
            }           
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de Facturer la commande  </div></>, GConf.TostInternetGonf)   
              setBtnState(true)
            }
          });
        
    }
    
    /*#########################[Card]##################################*/
    const StateCard = ({ status }) => {
        const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
        const statusCard = React.useCallback(() => {
          switch(status) {
            case 'W': return <StateCard color='warning' text='En Attent' />;  
            case 'A': return <StateCard color='success' text='Acepteé' /> ;
            case 'R': return <StateCard color='danger' text='Refuseé' />;
            default:  return <StateCard color='secondary' text='Indefinie' />;    
          }
        }, [status]);
      
        return (
          <div className="container">
            {statusCard()}
          </div>
        );
    };
    const TotaleCard = () =>{
        return(<>
                <div className='card card-body shadow-sm mb-2'>
                    <div className='row'>
                        <div className='col-4 border-end text-center'><h2>{FetchByGenre('W')}</h2><small>En Attent</small></div>
                        <div className='col-4 border-end text-center'><h2>{FetchByGenre('A')}</h2><small>Accepte</small></div>
                        <div className='col-4  text-center'><h2>{FetchByGenre('R')}</h2><small>Refusee</small></div>
                    </div>
                </div>
        </>)
    }
    const BtnsCard = () =>{
        return(<>
                <div className='card card-body shadow-sm mb-2'>
                <PieChartCard data={[{ name: 'En Attent', value: FetchByGenre('W') },{ name: 'Accepte', value: FetchByGenre('A') },{ name: 'Refuse', value: FetchByGenre('R') }]}/>
                </div>
        </>)
    }
    const PieChartCard =  (props) =>{
        
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
        return (
            <PieChart width={300} height={200} >
              <Pie
                data={props.data}
                cx={150}
                cy={100}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
              >
                {PieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          );
    }
    const CommandeHeader = () =>{
        return(<>
                <h2 className='text-center mb-4'>Commande </h2> 
                <br />
                <div className='row'>
                    <div className='col-6'>
                        <div className='text-secondary'><b>CODE COMMANDE : </b> {genre}</div>
                        <div className='text-secondary'><b>CLIENT: </b> {loading ? <NavLink  exact='true' to={`/S/cl/info/${commandeData.R_ID}`}> {commandeData.Name } </NavLink> : SKLT.BarreSkl } </div>
                    </div>
                    <div className='col-6'>
                        <div className='text-secondary'><b>Passé Le  : </b> {loading ?  new Date(commandeData.Passed_Day).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ) : SKLT.BarreSkl } </div>
                        <div className='text-secondary'><b>Voulu Le : </b> {loading ? new Date(commandeData.Wanted_Day).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' )  : SKLT.BarreSkl } </div>
                    </div>
                </div>
        </>)
    }
    
    return ( <> 
        <BreadCrumb links={GConf.BreadCrumb.RequestInfo} />
        <br />
        <div className="row">
            <div className="col-12 col-lg-12">
                <h1>{ComosedParamas} </h1>
                <br />
                {/* <div className="table-responsive">
                    {loading ? <RetunTableFunc data={rendredData} /> : ''}
                    
                </div>
                <br /> */}
                <TableGrid tableData={commandeData} columns={['*','PK','PID', 'Name','Tel','Gouv','Deleg','Adresse','Info']} />

                <br />
                <br />
            </div>
            
            {/* <div className="col-12 col-lg-4">
            <Bounce bottom>
                <div className="sticky-top" style={{top:'70px'}}>
                    <TotaleCard />
                    <BtnsCard />
                </div>
            </Bounce>
            </div> */}
        </div>
        {/* <FrameForPrint frameId='framed' src={printLink} /> */}
    </> );
}

export default FactureInfo;