import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-reveal';
import { Button, Dimmer, Form, Icon, Input, Loader, Modal, Pagination, Select, Tab, TextArea } from 'semantic-ui-react';
import GConf from '../../AssetsM/generalConf';
import BreadCrumb from '../Assets/breadCrumb'
import axios from 'axios';
import {toast } from 'react-toastify';
import SKLT from '../../AssetsM/usedSlk';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';

function ClientRegions() {
    //variables
    const Today = new Date()
    const {ID} = useParams()
    const [resquestData, setRequestData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [reqAllData, setReqAllData] = useState([])
   
    const [rendredPID, setRendredPID] = useState(0)
    const [rendredUID, setRendredUID] = useState(0)
    const [horaireIsSaved, setHoraireIsSaved] = useState([])
    const [activationIsSaved, setActivationIsSaved] = useState([])
    const [credentialIsSaved, setCredentialIsSaved] = useState([])
    const [subscriptionIsSaved, setSubscriptionIsSaved] = useState([])
    const [settingIsSaved, setSettingIsSaved] = useState([])

    const [saveBtnState, setSaveBtnState] = useState(false)
    const [loaderState, setLS] = useState(false)
    const [loading , setLoading] = useState(false)
    const panes = [
        {
          menuItem: { key: '01',  content: <span style={{color:'#009788'}}><b><span className='bi bi-send-fill'></span> Info Generale</b></span> , className:'rounded-pill'},
          render: () => <GeneralData />,
        },
        {
          menuItem: { key: '02',  content: <span style={{color:'#00bcd5'}}><b><span className='bi bi-person-fill'></span> Deja Enregistreé</b></span> , className:'rounded-pill' },
          render: () => <AlreadySaved />,
        },
    ]
 

    //useEffect
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/annuaire/addrequest/info`, {
            PK: ID,
          })
          .then(function (response) {
            CheckSavedState(JSON.parse(response.data[0].UserData).phone , response.data[0].Assigned_PID, response.data[0].Genre)
            setReqAllData(response.data[0])
            setRequestData(JSON.parse(response.data[0].ProfileData))
            setUserData(JSON.parse(response.data[0].UserData))
            setLoading(true)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger les regions </div></>, GConf.TostInternetGonf)   
              setLoading(true)
            }
        });
    }, [])
    
    //functions
    const SaveUserFunction = () =>{
        
        if (!userData.name) {toast.error("أدخل  الاسم و اللقب   !", GConf.TostErrorGonf)}
        else if (!userData.birthday) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else if (!userData.phone) {toast.error("أدخل  رقم الهاتف   !", GConf.TostErrorGonf)}
        else if (!userData.gouv) {toast.error("أدخل  الجنس   !", GConf.TostErrorGonf)}
        else{
            setLS(true)
            axios.post(`${GConf.ApiLink}/annuaire/addrequest/save/user`, {
                signUpD : {Name: userData.name, BirthDay: new Date(userData.birthday).toISOString().split('T')[0], Phone: userData.phone, Gender: 'male' } ,
                gouv: userData.gouv,
                deleg: userData.deleg,
                isSelected:'00',
                password: userData.phone

            }).then(function (response) {
                console.log(response.data)
                if(response.data.Saved == true) {
                        toast.success("تم التسجيل بنجاح !", GConf.TostSuucessGonf)
                        setRendredUID(response.data.UID)
                        setLS(false)
                 }
                else{
                    toast.error(' لم نتمكن من التسجيل حاول مرة اخري ', GConf.TostSuucessGonf)
                    setLS(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>مشل في الإتصال </h5> </div></>, GConf.TostInternetGonf) 
                  setLS(false)
                  }
              });

        }
    }
    const SaveDansAnnuaire = () => {
        if (!resquestData.name) {toast.error("أدخل  الاسم و اللقب   !", GConf.TostErrorGonf)}
        else if (!resquestData.phone) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else if (!resquestData.gouv) {toast.error("أدخل  رقم الهاتف   !", GConf.TostErrorGonf)}
        else if (!resquestData.deleg) {toast.error("أدخل  رقم الهاتف   !", GConf.TostErrorGonf)}
        else if (!resquestData.adresse) {toast.error("أدخل  الجنس   !", GConf.TostErrorGonf)}
        else{
            setLS(true)
            console.log(reqAllData.Genre)
            axios.post(`${GConf.ApiLink}/annuaire/addrequest/save/directory`, {
                tag : reqAllData.Genre,
                directoryData : resquestData ,
                targetPos: JSON.parse(reqAllData.Position),
                PK: ID,

            }).then(function (response) {
                if(response.data.PID) {
                        toast.success("تم التسجيل بنجاح !", GConf.TostSuucessGonf)
                        setLS(false)
                        setRendredPID(response.data.PID)
                 }  
                else{
                    toast.error(' لم نتمكن من التسجيل حاول مرة اخري ', GConf.TostSuucessGonf)
                    setLS(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>مشل في الإتصال </h5> </div></>, GConf.TostInternetGonf) 
                  setLS(false)
                  }
              });

        }
    }
    const SaveHoraire = () => {
        if (!reqAllData.Horaire) {toast.error("أدخل  الاسم و اللقب   !", GConf.TostErrorGonf)}
        else if (!reqAllData.HoraireALways) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else if (rendredPID == 0) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else{
            setLS(true)
            axios.post(`${GConf.ApiLink}/annuaire/addrequest/save/horaire`, {
                hiraire : reqAllData.Horaire,
                HoraireALways : reqAllData.HoraireALways,
                PID : rendredPID

            }).then(function (response) {
                console.log(response.data)
                if(response.data.affectedRows != 0 ) {
                        setHoraireIsSaved(reqAllData.Horaire)
                        toast.success("تم التسجيل بنجاح !", GConf.TostSuucessGonf)
                        setLS(false)
                 }
                else{
                    toast.error(' لم نتمكن من التسجيل حاول مرة اخري ', GConf.TostSuucessGonf)
                    setLS(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>مشل في الإتصال </h5> </div></>, GConf.TostInternetGonf) 
                  setLS(false)
                  }
              });

        }
    }

    const SaveActivation = () =>{
        if (rendredPID == 0) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else{
 
            setLS(true)
            axios.post(`${GConf.ApiLink}/annuaire/addrequest/save/activation`, {
                PID : rendredPID,
                ExpiredThe : require('moment')().add(2, 'months').format('YYYY-MM-DD')

            }).then(function (response) {
                if(response.data.affectedRows != 0 ) {
                        setActivationIsSaved(response.data)
                        toast.success("تم التسجيل بنجاح !", GConf.TostSuucessGonf)
                        setLS(false)
                 }
                else{
                    toast.error(' لم نتمكن من التسجيل حاول مرة اخري ', GConf.TostSuucessGonf)
                    setLS(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>مشل في الإتصال </h5> </div></>, GConf.TostInternetGonf) 
                  setLS(false)
                  }
              });

        }
    }
    const SaveCredentiels = () =>{
        if (rendredPID == 0) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else{
            setLS(true)
            axios.post(`${GConf.ApiLink}/annuaire/addrequest/save/login`, {
                PID : rendredPID,
                PhoneNum : resquestData.phone,
                SystemKey : reqAllData.Genre.charAt(0).toUpperCase() + reqAllData.Genre.slice(1)

            }).then(function (response) {
                console.log(response.data)
                if(response.data.affectedRows != 0 ) {
                        setCredentialIsSaved(response.data)
                        toast.success("تم التسجيل بنجاح !", GConf.TostSuucessGonf)
                        setLS(false)
                 }
                else{
                    toast.error(' لم نتمكن من التسجيل حاول مرة اخري ', GConf.TostSuucessGonf)
                    setLS(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>مشل في الإتصال </h5> </div></>, GConf.TostInternetGonf) 
                  setLS(false)
                  }
              });

        }
    }
    const SaveSouscription = () =>{
        if (rendredPID == 0) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else if (rendredUID == 0) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else{
            setLS(true)
            axios.post(`${GConf.ApiLink}/annuaire/addrequest/save/subscription`, {
                PID : rendredPID,
                UID : rendredUID,
                Post : reqAllData.Genre.charAt(0).toUpperCase() + reqAllData.Genre.slice(1)

            }).then(function (response) {
                console.log(response.data)
                if(response.data.affectedRows != 0 ) {
                        setSubscriptionIsSaved(response.data)
                        toast.success("تم التسجيل بنجاح !", GConf.TostSuucessGonf)
                        setLS(false)
                 }
                else{
                    toast.error(' لم نتمكن من التسجيل حاول مرة اخري ', GConf.TostSuucessGonf)
                    setLS(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>مشل في الإتصال </h5> </div></>, GConf.TostInternetGonf) 
                  setLS(false)
                  }
              });

        }
    }
    const SaveParametre = () =>{
        if (rendredPID == 0) {toast.error("أدخل تاريخ الميلاد   !", GConf.TostErrorGonf)}
        else{
            setLS(true)
            axios.post(`${GConf.ApiLink}/annuaire/addrequest/save/parametre`, {
                PID : rendredPID,
                tag : reqAllData.Genre,

            }).then(function (response) {
                console.log(response.data)
                if(response.data.affectedRows != 0 ) {
                        setSettingIsSaved(response.data)
                        toast.success("تم التسجيل بنجاح !", GConf.TostSuucessGonf)
                        setLS(false)
                 }
                else{
                    toast.error(' لم نتمكن من التسجيل حاول مرة اخري ', GConf.TostSuucessGonf)
                    setLS(false)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>مشل في الإتصال </h5> </div></>, GConf.TostInternetGonf) 
                  setLS(false)
                  }
              });

        }
    }

    const UpdateState = () =>{

    }
    const CheckSavedState = (phoneNum, PID, tag) => {
        if (PID != 0) { setRendredPID(PID)}
        axios.post(`${GConf.ApiLink}/annuaire/addrequest/savedstate`, {
            PID : PID,
            phoneNum : phoneNum,
            tag : tag,
          })
          .then(function (response) {
            console.log(response.data)
            if (response.data.userIsSaved.length != 0 ) { setRendredUID(response.data.userIsSaved[0].UID)}
            if (response.data.horaireIsSaved.length != 0 ) { setHoraireIsSaved(response.data.horaireIsSaved)}
            if (response.data.activationIsSaved.length != 0 ) { setActivationIsSaved(response.data.activationIsSaved)}
            if (response.data.credentialIsSaved.length != 0 ) { setCredentialIsSaved(response.data.credentialIsSaved)}
            if (response.data.subscriptionIsSaved.length != 0 ) { setSubscriptionIsSaved(response.data.subscriptionIsSaved)}
            if (response.data.settingIsSaved.length != 0 ) { setSettingIsSaved(response.data.settingIsSaved)}

          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger les regions </div></>, GConf.TostInternetGonf)   
               
            }
          });

    }
 
 


    //card
    const ButtonsCard = (props) =>{
        return(<>
                <div className='card card-body shadow-sm mb-2 border-div'>
                    <div className='row'>
                        <div className='col-12'>
                            <h5 className='mb-1'>I-User</h5>
                            <Button   fluid className='mb-3 rounded-pill bg-danger text-white' disabled={rendredUID != 0} onClick={() => SaveUserFunction()}><Icon name='save' /> Enregistrer L'Utulisateur </Button>
                        </div>
                    </div>
                        <hr />
                    <div className='row'>
                        <div className='col-6'>
                            <h5 className='mb-1'>I-annuaire</h5>
                            <Button   fluid className='mb-3 rounded-pill bg-danger text-white' disabled={rendredPID != 0} onClick={() => SaveDansAnnuaire()}><Icon name='save' /> Enregistrer Dans L'Annuaire </Button>
                        </div>
                        <div className='col-6'>
                            <h5 className='mb-1'>II-Horaire</h5>
                            <Button   fluid className='mb-3 rounded-pill bg-danger text-white' disabled={rendredPID == 0 || horaireIsSaved.length != 0} onClick={() => SaveHoraire()}><Icon name='save' /> Enregistrer L'horaire </Button>
                        </div>
                        <div className='col-6'>
                            <h5 className='mb-1'>III-System Activation</h5>
                            <Button   fluid className='mb-3 rounded-pill bg-danger text-white' disabled={rendredPID == 0 || activationIsSaved.length != 0} onClick={() => SaveActivation()}><Icon name='save' /> Enregistrer L'activation </Button>
                        </div>
                        <div className='col-6'>
                            <h5 className='mb-1'>IV-System LogIn</h5>
                            <Button   fluid className='mb-3 rounded-pill bg-danger text-white' disabled={rendredPID == 0 || credentialIsSaved.length != 0} onClick={() => SaveCredentiels()}><Icon name='save' /> Enregistrer les Credentiels </Button>
                        </div>

                        <div className='col-6'>
                            <h5 className='mb-1'>V-System Subscription</h5>
                            <Button   fluid className='mb-3 rounded-pill bg-danger text-white' disabled={rendredPID == 0 || rendredUID == 0 || subscriptionIsSaved.length != 0} onClick={() => SaveSouscription()}><Icon name='save' /> Enregistrer La Subscription </Button>
                        </div>
                        <div className='col-6'>
                            <h5 className='mb-1'>VI-System Setting</h5>
                            <Button   fluid className='mb-3 rounded-pill bg-danger text-white' disabled={rendredPID == 0 || settingIsSaved.length != 0} onClick={() => SaveParametre()}><Icon name='save' /> Enregistrer les Paramétre  </Button>
                        </div>
                        
                    </div>
                </div>
        </>)
    }
    
    const GeneralData = () => {
        return(<>
            <h5>Demande Info</h5>
            <div className="table-responsive">
                <table className="table table-striped">
                <tbody>
                    <tr>
                        <th scope="row">Nom</th>
                        <td>{loading ? resquestData.name :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Phone</th>
                        <td>{loading ? resquestData.phone :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Gouv</th>
                        <td>{loading ? resquestData.gouv :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Deleg</th>
                        <td>{loading ? resquestData.deleg :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Adresee</th>
                        <td>{loading ? resquestData.adresse :''}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <h5>User</h5>
            <div className="table-responsive">
                <table className="table table-striped">
                <tbody>
                    <tr>
                        <th scope="row">Nom</th>
                        <td>{loading ? userData.name :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Phone</th>
                        <td>{loading ? userData.phone :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Gouv</th>
                        <td>{loading ? userData.gouv :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Deleg</th>
                        <td>{loading ? userData.deleg :''}</td>
                    </tr>
                    <tr>
                        <th scope="row">Age</th>
                        <td>{loading ? userData.birthday :''}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <br />

        </>)
    }

    const AlreadySaved = () => {
        return(<>
            {rendredPID ? <h3> PID : {rendredPID} </h3> : ''}
            {rendredUID ? <h3> UID : {rendredUID} </h3> : ''}
            {credentialIsSaved ? <>
                <div className="table-responsive">
                    <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th scope="row">Identif</th>
                            <td>{loading ? credentialIsSaved[0].Identification :''}</td>
                        </tr>
                        <tr>
                            <th scope="row">Password</th>
                            <td>{loading ? credentialIsSaved[0].PasswordSalt :''}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </> : ''}
        
        </>)
    }

    return ( <> 
            <BreadCrumb links={GConf.BreadCrumb.ClientRegion} />
            <br />
            <div className='row'>
                <div className='col-12 col-lg-6'>
                    <h4>Date : {new Date(reqAllData.Req_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' )} | System Key : {reqAllData.Genre}</h4>  
                    <Tab menu={{ secondary: true , style: {overflowX : 'auto', overflowY : 'hidden', paddingBottom:'5px' }}} panes={panes} />
                    
                </div>
                <div className='col-12 col-lg-6'>
                    <ButtonsCard />
                </div>
            </div>
           
        </> );

}

export default ClientRegions;