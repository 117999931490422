import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import GConf from '../../AssetsM/generalConf';
import { Button , Icon, Label,   Form,  Input, Loader, Select, TextArea, Modal, Dimmer} from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';
import TableGrid from '../../AssetsM/Cards/tableGrid';
import { NavLink } from 'react-router-dom';
import { _ } from "gridjs-react";
import SKLT from '../../AssetsM/usedSlk';

function FournisseurPage() {
    /*################[Variable]###############*/
    let  [clientList, setClientList] = useState([SKLT.TableSlt]); 
    let navigate = useNavigate();
    let Offline = JSON.parse(localStorage.getItem(`${GConf.PID}_Offline`));
    const colors = [
        'red',
        'orange',
        'yellow',
        'olive',
        'green',
        'teal',
        'blue',
        'violet',
        'purple',
        'pink',
        'brown',
        'grey',
        'black',
    ]
    const  [fsList, setFSList] = useState([]); 
    const [modalS, setModalS] = useState(false)
    const [fournisseurData, setFSData] = useState({MK_Depart : new Date().toISOString().split('T')[0], MK_Termine : new Date().toISOString().split('T')[0] , MK_Result : []})
    const [inDirArticle, setInDirA] = useState({});
    const [saveBtnState, setSaveBtnState] = useState(false)
    const [loaderState, setLS] = useState(false)
 
    const [delegList ,setDelegList] = useState([]) 
    const tableaux = [
      { key: 1 , value: 'Application', text: 'Application' },
      { key: 2 , value: 'System', text: 'System' },
    ]

    const EtatDeMk = [
        { key: 1 , value: 'En Cours', text: 'En Cours' },
        { key: 2 , value: 'Terminee', text: 'Termineé' },
        { key: 3 , value: 'Planifier', text: 'Planifier' },
      ]

      const VEIC = [
        { key: 1 , value: 'af', text: 'Vital' },
        { key: 2 , value: 'af', text: 'Essentiel' },
        { key: 3 , value: 'af', text: 'Intermédiaire' },
        { key: 3 , value: 'af', text: 'Confort' },
      ]
    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/other/marketing`, {
            PID : GConf.PID,
          })
          .then(function (response) {
            
            let testTable = []
              response.data.map( (getData) => testTable.push([
            _(<AvatarCard lettre={capitalizeFirstLetter(getData.MK_Name)} />),
            getData.MK_Genre,
            _(<StateCard status={getData.MK_State} />),
            getData.MK_Name,
            getData.MK_Genre,
            getData.MK_Budget,
            getData.MK_Objectif,
            getData.MK_Audiance,
            _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/S/sk/info/${getData.PK}`)}><span className='d-none d-lg-inline'> Info </span> </Button>)
            ],))
            setClientList(testTable)
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment des ancien Camion  </div></>, GConf.TostInternetGonf)   
              setClientList(Offline.camion)
            }
          });
    }, [])


    /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const  capitalizeFirstLetter = (string) =>{
        return (string.charAt(0).toUpperCase() + string.slice(1)).charAt(0);
    }
 
    const AddToResultFunc = (event) => {
        if (!inDirArticle.Grandeur) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
        else if (!inDirArticle.Valeur) {toast.error("Dosage Invalide !", GConf.TostErrorGonf)}
        else{
             
            let arrayToAdd = {id: fournisseurData.MK_Result.length + 1 ,   Grandeur: inDirArticle.Grandeur, Valeur: inDirArticle.Valeur }
            fournisseurData.MK_Result.push(arrayToAdd)
            setInDirA({})   
                    
        }
                
    }
    const SaveFournisseur = (event) => {
        if (!fournisseurData.MK_Name) {toast.error("Nom Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_Genre) {toast.error("Dosage Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_Depart) {toast.error("Forme Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_Termine) {toast.error("Presentation Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_Result) {toast.error("DCI Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_Budget) {toast.error("Classe Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_Audiance) {toast.error("Sous_Classe Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_Objectif) {toast.error("Laboratoire Invalide !", GConf.TostErrorGonf)}
        else if (!fournisseurData.MK_State) {toast.error("AMM Invalide !", GConf.TostErrorGonf)}
         
        else{
            setLS(true)
            axios.post(`${GConf.ApiLink}/other/marketing/add`, {
                PID : GConf.PID,
                toAddData : fournisseurData,
            }).then(function (response) {
                if(response.data.affectedRows) {
                    setSaveBtnState(true)
                    toast.success("Client Ajouter !", GConf.TostSuucessGonf)
                    
                    setLS(false)
                }
                else{
                    toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                    setLS(false)
                    }
            }).catch((error) => {
                if(error.request) {
                    toast.error(<><div><h5>Probleme de Connextion</h5> Le client sera enregistrer sur votre ordinateur   </div></>, GConf.TostInternetGonf)   
                    Offline.clientToSave.push(fournisseurData)
                    localStorage.setItem(`${GConf.PID}_Offline`,  JSON.stringify(Offline));
                    setLS(false)
                    setSaveBtnState(true)
                }
                });
                    
        }
                
    }

    /*################[Card]###############*/
     const AvatarCard = (props) =>{
            return(<>
                <Label size='massive' circular color={colors[Math.floor(Math.random() * 10)]} key={1}>
                    <h3>{props.lettre}</h3>
                </Label>
                
            </>)
    }
    const StateCard = ({ status }) => {
        const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
        const statusCard = React.useCallback(() => {
          switch(status) {
            case 'ABYEDH': return <StateCard color='info' text='Publique' />;  
            default:  return <StateCard color='success' text='Priveé' />;    
          }
        }, [status]);
      
        return (
          <div className="container">
            {statusCard()}
          </div>
        );
    };
    return ( 
        <>
                
                <div className='row'>
                    <div className='col-6 text-start'><NavLink exaxt='true' to='/S/th'><Button className='rounded-circle' icon='arrow left' /></NavLink></div>
                    <div className='col-6 text-end'> <Button icon='add plus' className='rounded-pill bg-system-btn mb-0' onClick={() => setModalS(true)} /></div>
                </div>
 
                <br /> 
 
                <TableGrid tableData={clientList} columns={GConf.TableHead.markeingPage} />
                
                <Modal
                    size='large'
                    open={modalS}
                    closeIcon
                    onClose={() => setModalS(false)}
                    onOpen={() => setModalS(true)}
                >
                    <Modal.Header><h4> Ajouter Nouveaux Medicamment </h4></Modal.Header>
                    <Modal.Content scrolling>
                     
                            <Dimmer inverted active={loaderState}>
                                <Loader />
                            </Dimmer>
                            <div className='row'>
                               <div className='col-6'>
                                <div className='row'>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Nom de comapign:</h5>
                                            <Input icon='medkit' iconPosition='left' placeholder='Nom de comapign' className='w-100 border-0 shadow-sm rounded mb-1' value={fournisseurData.MK_Name}   onChange={(e) => setFSData({...fournisseurData, MK_Name: e.target.value })}/>
                                        </div>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Genre de Compaign :</h5>
                                            <Select placeholder='Genre de Compaign' options={tableaux}  className='w-100 shadow-sm rounded mb-3' onChange={(e, data) => setFSData({...fournisseurData, MK_Genre: data.value })} />
                                        </div>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Depart Le:</h5>
                                            <Input icon='medkit' type='date' iconPosition='left'   className='w-100 border-0 shadow-sm rounded mb-1'   value={fournisseurData.MK_Depart}   onChange={(e) => setFSData({...fournisseurData, MK_Depart: e.target.value })}/>
                                        </div>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Termine Le:</h5>
                                            <Input icon='medkit' type='date' iconPosition='left'   className='w-100 border-0 shadow-sm rounded mb-1'   value={fournisseurData.MK_Termine}   onChange={(e) => setFSData({...fournisseurData, MK_Termine: e.target.value })}/>
                                        </div>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Budget:</h5>
                                            <Input icon='medkit' iconPosition='left' placeholder='Budget' className='w-100 border-0 shadow-sm rounded mb-1' value={fournisseurData.MK_Budget}   onChange={(e) => setFSData({...fournisseurData, MK_Budget: e.target.value })}/>
                                        </div>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Audiance :</h5>
                                            <Input icon='medkit' iconPosition='left' placeholder='Audiance ' className='w-100 border-0 shadow-sm rounded mb-1' value={fournisseurData.MK_Audiance} onChange={(e) => setFSData({...fournisseurData, MK_Audiance: e.target.value })} />
                                        </div>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Objectif :</h5>
                                            <Input icon='medkit' iconPosition='left' placeholder='Objectif ' className='w-100 border-0 shadow-sm rounded mb-1' value={fournisseurData.MK_Objectif} onChange={(e) => setFSData({...fournisseurData, MK_Objectif: e.target.value })} />
                                        </div>
                                        <div className='col-12 p-1 mb-2'>
                                            <h5 className='mb-1'>Etat :</h5>
                                            <Select placeholder='Choisir Etat' options={EtatDeMk}  className='w-100 shadow-sm rounded mb-3' onChange={(e, data) => setFSData({...fournisseurData, MK_State: data.value })} />
                                        </div>
                                </div>
                               </div>
                               <div className='col-6'>
                                    <div className='row'>
                                        <div className='card p-4 border-div '>
                                        <h5 className='mb-1'>Grandeur :</h5>
                                            <Input icon='medkit' iconPosition='left' placeholder='Grandeur ' className='w-100 border-0 shadow-sm rounded mb-1' value={inDirArticle.Grandeur} onChange={(e) => setInDirA({...inDirArticle, Grandeur: e.target.value })} />
                                            <h5 className='mb-1 mt-1'>Valeur :</h5>
                                            <Input icon='medkit' iconPosition='left' placeholder='Valeur ' className='w-100 border-0 shadow-sm rounded mb-1' value={inDirArticle.Valeur} onChange={(e) => setInDirA({...inDirArticle, Valeur: e.target.value })} />
                                            <Button  onClick={() => AddToResultFunc()} disabled={saveBtnState} className=' rounded-pill bg-system-btn mt-2' positive>  <Icon name='save outline' /> Ajouter </Button>
                                        </div>  
                                        {
                                            fournisseurData.MK_Result.map((data,index) => <span key={index}>{data.Grandeur} : {data.Valeur}</span>)
                                        }
                                    </div>
                               </div> 

                            </div>
                            
                            <div className='text-end mb-2'>
                                <Button  onClick={() => SaveFournisseur()} disabled={saveBtnState} className='text-end rounded-pill bg-system-btn ' positive>  <Icon name='save outline' /> Enregistrer </Button>
                                    
                            </div>
        

                    </Modal.Content>
                    <Modal.Actions>
                                <Button className='rounded-pill' negative onClick={ () => setModalS(false)}> <span className='bi bi-x' ></span> Fermer</Button>
                    </Modal.Actions>
                </Modal>
               
        </>);
}

export default FournisseurPage;