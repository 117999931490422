import React, { useEffect, useState } from 'react';
import InputLinks from '../Assets/linksData'
import BackCard from '../Assets/backCard'
import { Fade } from 'react-reveal';
import { Button,  Divider,  Dropdown, Icon, Input, Label, Loader, Form, Select, Tab, TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import GConf from '../../../AssetsM/generalConf';
import axios from 'axios';
import FrameForPrint from '../../../Dashboard/Assets/frameForPrint';
import usePrintFunction from '../../../Dashboard/Assets/Hooks/printFunction';
import RoutingMachine from "../../../AssetsM/RoutingMachine";
import { MapContainer, TileLayer, Marker, Popup,   useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { useParams } from 'react-router-dom';

const HoraireCard = ({alwaysState, setAlwaysState, timming, setPauseDay , SetTimmingData, UpdateTimmingData, setSelectedUpdateDay, selectedUpdateDay}) =>{
    const weekDays = [
        { key: 'af', value: 'Lun', text: 'الانثنين' },
        { key: 'ax', value: 'Mar', text: 'الثلاثاء' },
        { key: 'al', value: 'Mer', text: 'الاربعاء' },
        { key: 'dz', value: 'Jeu', text: 'الخميس' },
        { key: 'as', value: 'Vend', text: 'الجمعة' },
        { key: 'ad', value: 'Sam', text: 'السبت' },
        { key: 'ao', value: 'Dim', text: 'الاحد' },
      ]
    const ArabificationDate = (dateName) =>{
        switch (dateName) {
            case 'Lun' : return 'الإثــنين' 
            break;
            case 'Mar' : return 'الثلاثــاء'
            break;
            case 'Mer' : return 'الإربــعاء'
            break;
            case 'Jeu' : return 'الخميس'
            break;
            case 'Vend' : return 'الجـمعة'
            break;
            case 'Sam' : return 'الســبت'
            break;
            case 'Dim' : return 'الأحـــد'
            break;

            default:
                break;
        }
    }
    const DayHoraireOLD = (props) =>{
        return(<>
                <div className='row mb-1' >
                    <div  className='col-6 col-lg-3 order-1 order-lg-1 align-self-center mb-2'>
                        <b>{ArabificationDate(props.data.day)}</b>
                    </div>
                    <div  className='col-12 col-lg-4 order-2'>
                        <div className='row'>
                            <div className='col-6'><Input  type='time' size='mini'   disabled={props.data.dayOff}  fluid className='mb-1 w-100' defaultValue={props.data.matin.start} onChange={(e) => SetTimmingData(props.data.day,'matin','start',e.target.value)} /></div>
                            <div className='col-6'><Input  type='time' size="mini"   disabled={props.data.dayOff}  fluid className='mb-1 w-100' defaultValue={props.data.matin.end} onChange={(e) => SetTimmingData(props.data.day,'matin','end',e.target.value)}/></div>
                        </div>
                    </div>
                    <div  className='col-12 col-lg-4 order-3'>
                        <div className='row'>
                            <div className='col-6'><Input  type='time' size="mini"   disabled={props.data.dayOff}  fluid className='mb-1 w-100' defaultValue={props.data.soir.start} onChange={(e) => SetTimmingData(props.data.day,'soir','start',e.target.value)}/></div>
                            <div className='col-6'><Input  type='time' size="mini"   disabled={props.data.dayOff}  fluid className='mb-1 w-100' defaultValue={props.data.soir.end} onChange={(e) => SetTimmingData(props.data.day,'soir','end',e.target.value)} /></div>
                        </div>
                    </div>
                    <div  className='col-6 col-lg-1 align-self-center order-1 order-lg-4 mb-2'>
                        <div className="form-check form-switch">
                            <input className="form-check-input form-check-input-lg" type="checkbox"  onChange={() => setPauseDay(props.data.day,props.data.dayOff)}   />
                        </div>
                    </div>
                </div>
        </>)
    }
    const DayHoraire = (props) =>{
        return(<>
                <div className={`row mb-1 ${props.data.dayOff ? 'd-none':''}`}>
                    <div  className='col-4 col-lg-4 '>
                        <b>{ArabificationDate(props.data.day)}</b>
                    </div>
                    <div  className='col-4 col-lg-4  '>
                        <small>{props.data.matin.start} -- {props.data.matin.end}</small>
                    </div>
                    <div  className='col-4 col-lg-4  '>
                    <small>{props.data.soir.start} -- {props.data.soir.end}</small>
                    </div>
                </div>
        </>)
    }

    return(<>
        <div className='card-body font-droid' dir='rtl'>
            <h5 className='text-end text-secondary font-droid' dir='rtl'> <span className='bi bi-calendar-week-fill'></span>   أوقات العمل  </h5>
            <div className='row '>
                <div className='col-12 col-lg-7'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-8 col-lg-9 align-self-center'> 
                                <h5 className='mb-0 font-droid'>مفتوح دائما</h5>  
                                <small>عند تفعيل هذه الخاصية ستضهر في حالة مفتوح دائما </small>
                            </div>
                            <div className='col-4 col-lg-3  align-self-center '> 
                                <div className="form-check form-switch">
                                    <input className="form-check-input form-check-input-lg" type="checkbox"  onChange={() => setAlwaysState(!alwaysState)}  checked={alwaysState} />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div className='row text-danger mb-2 '>
                            <div  className='col-4 col-lg-4'> <b>اليوم</b> </div>
                            <div  className='col-4 col-lg-4'> <small>صباح</small> </div>
                            <div  className='col-4 col-lg-4'> <small>مساء</small> </div>
                        </div>
                        
                        {
                            timming.map( (data,index) => <DayHoraire key={index} data={data} />)
                        }
                    </div>
                </div>
                <div className='col-12 col-lg-5'>
                    <div className='card card-body border-div'>
                        <h5 className='font-droid'>قم باختيار يوم لتعديل الوقت </h5>
                        <Select options={weekDays} onChange={(e, { value }) => setSelectedUpdateDay(value)} className='mb-3'/>
                        <div className='row mb-3 '>
                            <div className='col-6'><Input  type='time' size='mini'  value={timming.find(obj => obj.day === selectedUpdateDay).matin.start}  fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'matin','start',e.target.value)} /></div>
                            <div className='col-6'><Input  type='time' size="mini"  value={timming.find(obj => obj.day === selectedUpdateDay).matin.end} fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'matin','end',e.target.value)}/></div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-6'><Input  type='time' size='mini'  value={timming.find(obj => obj.day === selectedUpdateDay).soir.start}   fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'soir','start',e.target.value)} /></div>
                            <div className='col-6'><Input  type='time' size="mini"  value={timming.find(obj => obj.day === selectedUpdateDay).soir.end}  fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'soir','end',e.target.value)}/></div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-2 text-end'>
                                <div className="form-check form-switch">
                                    <input className="form-check-input form-check-input-lg" type="checkbox" checked={timming.find(obj => obj.day === selectedUpdateDay).dayOff}   onChange={() => setPauseDay(selectedUpdateDay,selectedUpdateDay.dayOff)}   />
                                </div>
                            </div>
                            <div className='col-10'>يوم راحة ؟ </div>
                        </div>
                        
                        <Button size='mini'     className='rounded-pill    font-droid' onClick={() => UpdateTimmingData()} fluid  >   <Icon name='time' /> تعديل  </Button>
                    </div>
                </div>
            </div>
            
        </div>
    </>)
}

const GeneralData = ({entreData, setEntreData, Offline, SaveEntree, loaderState,   PID, saveBtnState}) =>{
    return(<>
        <div className='card card-body border-div shadow-sm mb-3'>
            <h3> PID: {PID ? PID : 0}</h3>

            <div className='mb-4'>
                <h5 className='mb-0'> Nom  </h5>
                <Input fluid icon='users' iconPosition='left'  placeholder='Nom' value={entreData.Name} onChange={(e) => setEntreData({...entreData, Name: e.target.value })}   />
            </div>
            <div className='mb-4'>
                <h5 className='mb-0'> Telephone  </h5>
                <Input fluid icon='phone' iconPosition='left'   placeholder='Tel' value={entreData.Phone} onChange={(e) => setEntreData({...entreData, Phone: e.target.value })}  />
            </div>
            <div className='mb-4'>
                <div className='mb-2 text-secondary'>Gouv :  {Offline.localData[0] ? Offline.localData[0].gouv : ''}</div>
                <div className='mb-2 text-secondary'>Deleg :  {Offline.localData[0] ? Offline.localData[0].deleg : ''}</div>
            </div>
            <div className='mb-4'>
                <h5 className='mb-0'> Adressse</h5>
                <Form>
                    <TextArea placeholder='Adresse'  value={entreData.Adress} onChange={(e) => setEntreData({...entreData, Adress: e.target.value })} />
                </Form>

                <div className='card-body'>
                    <Button disabled={saveBtnState} onClick={() => SaveEntree()} fluid className='rounded-pill bg-system-btn ' positive>  <Icon name='map pin' /> Enregistreé  <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/> </Button>
                </div>
            </div>        
        </div>
    </>)
}

function EditProfile() {
    /*#########################[Const]##################################*/
    const Today = new Date()
    let {genre, PID} = useParams();
    let Offline = JSON.parse(localStorage.getItem(`Admin_Dir_Offline`)); 
    const [allData, setAllData] = useState([])
    const [entreData, setEntreData] = useState({Name :'', Phone:'', Adress :'' , Gouv: Offline.localData[0] ? Offline.localData[0].gouv : '' , Deleg: Offline.localData[0] ? Offline.localData[0].deleg : ''})
    const [saveBtnState, setSaveBtnState] = useState(false)
    const [loaderState, setLS] = useState(false)
    const [loading, setLoading] = useState(false)

    const [position, setPosition] = useState([36.17720,9.12337])
    const [myPosition, setMyPosition] = useState([36.17720,9.12337]) 
    
    
    let [selectedUpdateDay , setSelectedUpdateDay] = useState('Lun')
    let [alwaysState , setAlwaysState] = useState(false)
    let [timming, setTimming] = useState([{day:"Lun",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"16:00"}},{day:"Mar",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"16:00"}},{day:"Mer",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"16:00"}},{day:"Jeu",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"16:00"}},{day:"Vend",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"16:00"}},{day:"Sam",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"16:00"}},{day:"Dim",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"16:00"}}])

    /*Images */
    const [imagesListe, setImagesListe] = useState([])
    const [uploadImageName, setUploadImageName] = useState('')
    const [uploadImage, setUploadImages] = useState()
    const [formaDataArr, setFormDataArr] = useState()
    const [displayedImage, setDisplayedImage] = useState()
    const [todisplayedImage, setToDisplayedImage] = useState([])

    const panes = [
        {
            menuItem: { key: 'start', icon: 'add circle', content: 'Info ' }, 
            render: () => <GeneralData entreData={entreData} setEntreData={setEntreData} Offline={Offline} SaveEntree={SaveEntree} loaderState={loaderState}   PID={PID} saveBtnState={saveBtnState} />,
        },
        {
            menuItem: { key: 'stfffart', icon: 'map marker alternate', content: 'Position ' },
            render: () =>  <PositionCard />,
        },
        {
            menuItem: { key: 'client', icon: 'image', content: 'Images' }, 
            render: () => <ImageCard />,
        }, 
        {
            menuItem: { key: 'fdff', icon: 'time', content: 'Horaire' }, 
            render: () =><HoraireCard alwaysState={alwaysState} setAlwaysState={setAlwaysState} timming={timming} setPauseDay={setPauseDay} SetTimmingData={SetTimmingData} setSelectedUpdateDay={setSelectedUpdateDay} selectedUpdateDay={selectedUpdateDay} UpdateTimmingData={UpdateTimmingData} />,
        },        
    ]
    L.Icon.Default.mergeOptions(GConf.LeafleftIcon );
    const icon = L.icon(GConf.LeafleftIconP);

    /*#########################[Use Effect]##################################*/
    useEffect(() => {
        //get position 
        navigator.geolocation.getCurrentPosition(
            function(position) {
                if (!position.coords.latitude) {toast.error(<><div><h5>ENTRE VOTRE POSITION Lat</h5></div></>, GConf.TostInternetGonf)}
                else if (!position.coords.longitude) {toast.error(<><div><h5>ENTRE VOTRE POSITION Lng</h5></div></>, GConf.TostInternetGonf)}
                else{
                    setMyPosition([position.coords.latitude, position.coords.longitude])
                    setPosition({Lat:position.coords.latitude, Lng:position.coords.longitude})
                    console.log({lat:position.coords.latitude, lng:position.coords.longitude})
                }
            },
            function(error) {
                toast.error(<><div><h5>ENTRE VOTRE POSITION</h5></div></>, GConf.TostInternetGonf)
            }
        );
        axios.post(`https://api.abyedh.tn/apiAbyedh/Search/profile`, {
            tag: genre,
            PID:PID,
          })
          .then(function (response) {
            setLoading(false)
            setEntreData({Name : response.data.genrale[0].Name , Phone: response.data.genrale[0].Phone , Adress :response.data.genrale[0].Adress , Gouv: Offline.localData[0] ? Offline.localData[0].gouv : '' , Deleg: Offline.localData[0] ? Offline.localData[0].deleg : ''})
            console.log(response.data)
            setAllData(response.data)
            //horaire / photoes / 
          })
    }, [])

    /*#########################[Functions]##################################*/
 
    const SaveEntree = () =>{
            if (!entreData.Name) {toast.error("Nom est Invalide !", GConf.TostErrorGonf)}
            else if (!entreData.Gouv ) {toast.error("Gouv est Invalide !", GConf.TostErrorGonf)}
            else if (!entreData.Deleg) {toast.error("Deleg est Invalide !", GConf.TostErrorGonf)}
            else if (!entreData.Adress ) {toast.error("Adresse list est Invalide !", GConf.TostErrorGonf)}
            else {
                setLS(true)
                axios.post(`${GConf.ApiInputLink}/modifier/generale`, {
                    tag: genre,
                    entreData: entreData,
                    PID: PID,
                })
                .then(function (response) {
                    if(response.status = 200) {
                        setSaveBtnState(true)
                         
                        toast.success("Facture Enregistreé !", GConf.TostSuucessGonf)
                        setLS(false)
                    }
                    else{
                        toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                        setLS(false)
                    }
                }).catch((error) => {
                    if(error.request) {
                      toast.error(<><div><h5>Probleme de Connextion</h5> La facture sera enregisstrer sur votre appareil  </div></>, GConf.TostInternetGonf)   
                      Offline.ajoutee.push(entreData)
                      localStorage.setItem(`Admin_Dir_Offline`,  JSON.stringify(Offline));
                      setLS(false)
                    }
                });

            }       
    }
    const EditPosition = () =>{
        if (!PID) {toast.error("PID est Invalide !", GConf.TostErrorGonf)}
        else if (!myPosition[0]) {toast.error("Lng est Invalide !", GConf.TostErrorGonf)}
        else if (!myPosition[1] ) {toast.error("Lat est Invalide !", GConf.TostErrorGonf)}
        else {
            setLS(true)
            axios.post(`${GConf.ApiInputLink}/nouveaux/position`, {
                PID: PID,
                position: {Lat :myPosition[0], Lng: myPosition[1]} ,
            }).then(function (response) {
                if(response.data.affectedRows) {
                    toast.success("Position Enregistrer !", GConf.TostSuucessGonf)
                    setLS(false)
                }
                else{
                    toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                }
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5>Probleme de Connextion</h5> La commande sera enregisstrer sur votre appareil  </div></>, GConf.TostInternetGonf)   
                }
            });

        }       
    }
    const UpdateTimmingData = (day,time,genre,value) => {
        //setTimming(...timming)
        //setTest(Math.random())
        toast.success("", GConf.TostAddedToTimming)

    }
    const setPauseDay = (day,state) =>{
        const targetIndex = timming.findIndex(element => element.day === day)
        let copyOfHoraire = timming
        copyOfHoraire[targetIndex].dayOff = !state
        setTimming(copyOfHoraire)
    }
    const SetTimmingData = (day,time,genre,value) => {
        const targetIndex = timming.findIndex(element => element.day === day)
        let copyOfHoraire = timming

        if (time == 'matin') {
            if (genre == 'start') {
                copyOfHoraire[targetIndex].matin.start = value
                setTimming(copyOfHoraire)
            } else {
                copyOfHoraire[targetIndex].matin.end = value
                setTimming(copyOfHoraire)
            }
        } else {
            if (genre == 'start') {
                copyOfHoraire[targetIndex].soir.start = value
                setTimming(copyOfHoraire)
            } else {
                copyOfHoraire[targetIndex].soir.end = value
                setTimming(copyOfHoraire)
            }
        }

    }

    const MapEventsHandler = ({ onLocationSelected }) => {
        useMapEvents({
          click: (e) => {
            const { lat, lng } = e.latlng;
            onLocationSelected({ lat, lng });
          },
        });
      
        return null;
    };
    const handleLocationSelected = (location) => {
    setPosition({Lat: location.lat , Lng:location.lng})
    };
    const GetMyLocation = () =>{
        navigator.geolocation.getCurrentPosition(
            function(position) {
                if (!position.coords.latitude) {toast.error(<><div><h5>ENTRE VOTRE POSITION Lat</h5></div></>, GConf.TostInternetGonf)}
                else if (!position.coords.longitude) {toast.error(<><div><h5>ENTRE VOTRE POSITION Lng</h5></div></>, GConf.TostInternetGonf)}
                else{
                    setPosition({Lat:position.coords.latitude, Lng:position.coords.longitude})
                }
            },
            function(error) {
                toast.error(<><div><h5>ENTRE VOTRE POSITION</h5></div></>, GConf.TostInternetGonf)
            }
        );
    }

    /*Images */
    const UploadImageFunc = (e) => {
        setDisplayedImage(URL.createObjectURL(e.target.files[0]))
        setUploadImages(e.target.files[0])
    }
    const UpdateImageFunc = (genre) =>{
        if (!uploadImage) {toast.error("Image est Invalide !", GConf.TostErrorGonf) } 
        else {
            const formData = new FormData();
            formData.append("Images", uploadImage);
            formData.append("PID", GConf.PID);
            formData.append("Genre", genre);
            setLS(true)
            axios({
                method: 'post',
                url: `${GConf.ApiInputLink}/nouveaux/image`,
                data: formData ,
            }).then(function (response) {
                toast.success("Image Enregistreé !", GConf.TostSuucessGonf)
                setLS(false)
            }).catch((error) => {
                toast.error(<><div><h5>Probleme de Connextion</h5> La commande sera enregisstrer sur votre appareil  </div></>, GConf.TostInternetGonf)
            });  
        } 
    }
    const handleFileSelect = (event)  =>{
        const files = event.target.files;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            const filename = `restaurant_${GConf.PID}_${i}-`;
            formData.append('Images', files[i], filename);
        }
        formData.append("PID", GConf.PID);
        //setDisplayedImage(URL.createObjectURL(event.target.files[0]))
        const uploadedImages = Array.from(event.target.files);
        setToDisplayedImage(uploadedImages);
        //files.map(() => console.log(files.length))
        
        setFormDataArr(formData);
        //UpdateImageFuncMultiple(formData);
    }
    const UpdateImageFuncMultiple = (formData) =>{
        axios.post(`${GConf.ApiLink}/profile/images/ajouter`, formData)
        .then(response => console.log(`Done ` + response.data))
        .catch(error => console.log(error));
    }
    // const UpdateImageFunc = () =>{
    //     if (!uploadImage) { } 
    //     else if (!uploadImageName) { }
    //     else {
    //         const formData = new FormData();
    //         formData.append("ProfileImage", uploadImage);
    //         formData.append("Tag", uploadImageName);
    //         formData.append("PID", GConf.PID);
    //         axios({
    //             method: 'post',
    //             url: `${GConf.ApiLink}/profile/images/ajouter`,
    //             data: formData ,
    //         }).then(function (response) {
    //             console.log(response.data)
    //         }).catch((error) => {
    //             console.log(error)
    //         });  
    //     } 
    // }
    // const UploadImageFunc = (e) => {
    //     setDisplayedImage(URL.createObjectURL(e.target.files[0]))
    //     setUploadImages(e.target.files[0])
    // }
    const RemoveImageFunc = (imgName) => {
        console.log(imgName.slice(0, -4))
        axios.post(`${GConf.ApiLink}/profile/images/deletefile`, {
            fileName : imgName,
        }).then(function (response) {
            console.log(response.data)
            if(response.data.affectedRows) {
                toast.success("Image Supprimeé !", GConf.TostSuucessGonf)
                setLS(false)
            }
            else{
                toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                setLS(false)
            }
        })
    }
    /*#########################[Component]##################################*/ 
    const PositionCard = () =>{
        return(<>
            <div className='mb-3'>
                    <Button  fluid className='rounded-pill mb-2 ' onClick={() => GetMyLocation()}>Ma Position </Button> 
                    <MapContainer center={myPosition} zoom={15} scrollWheelZoom={false} className=" cursor-map-crosshair border-div" style={{height:'400px'}}>
                        <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MapEventsHandler onLocationSelected={handleLocationSelected} />
                        <Marker position={[position.Lat,position.Lng]}>
                            <Popup>
                                
                            </Popup>
                        </Marker>
                        <Marker position={myPosition}>
                            <Popup>
                                My Position
                            </Popup>
                        </Marker>
                    </MapContainer> 
                    {/* <LocationPicker    />*/}
            </div>
        </>)
    }

    const ImageCard = () =>{
        const UploadImageCard = () =>{
            return(<>
                <div className='card p-2 shadow-sm mb-2 border-div'>
                    <div className='row'>
                        <div className='col-6 align-self-center text-center'>
                                <label onChange={handleFileSelect} htmlFor="formId"   className='text-info' role="button">
                                    <Input type='file' hidden name="Images" id="formId" multiple />
                                    <span className='bi bi-cloud-upload-fill bi-md'> </span> 
                                </label>
                                
                        </div>
                        <div className='col-6'>
                            <Button   className='rounded-pill bg-system-btn' size='tiny' onClick={() => UpdateImageFuncMultiple(formaDataArr)} ><Icon name='save' /> Enregistreé <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                        </div>
                        
                    </div>
                </div>
            </>)
        }
        const DisplayImageCard = (props) => {
            return(<>
                <div className='card card-body shadow-m mb-2 border-div'>
                    <div className='row'>
                       <div className='col-4'><img src={`https://cdn.abyedh.com/images/Directory/${props.imageLink}`} className='border border-2 rounded shadow-sm' width='200px' height='90px'  /></div> 
                       <div className='col-4'></div> 
                       <div className='col-4'><Button onClick={() => RemoveImageFunc(props.imageLink)}>Delete Btn</Button></div> 
                    </div>
                </div>
            </>)
        }
        const PasDeResultat = () =>{
            return(<>
                <div className='text-center'>
                        {/* <h3>
                            <span className='bi bi-exclamation-triangle-fill text-info bi-md'></span> 
                            Vous n'avait pas d'images
                        </h3> */}
                        <label onChange={handleFileSelect} htmlFor="formId"   className='text-info' role="button">
                                <Input type='file' hidden name="Images" id="formId" multiple />
                                <img src='https://assets.ansl.tn/Images/usful/uploadImage.jpg' width='100%'  height='150px' />
                        </label>
                        <h3>
                            Cliquer Pour Charger des Imgaes 
                        </h3>
                </div>
            </>)
        }
        return(<>
            {imagesListe.length == 0 ?
                <>
                    {/* <UploadImageCard title='Image de Profile' tag='profile' />  */}
                    <br />
                    <div className='row'>
                            {todisplayedImage.length != '0' ? 
                            <>
                                {todisplayedImage.map((data,index) => 
                                        <div className='col-12 mb-3' key={index}>
                                            <img src={URL.createObjectURL(todisplayedImage[index])} className='border border-2 rounded shadow-sm' width='100%' height='140px'  />
                                        </div>
                                )}
                                
                                <br />
                                <div className='text-end'>
                                    <Button   className='rounded-pill bg-system-btn' size='tiny' onClick={() => UpdateImageFuncMultiple(formaDataArr)} ><Icon name='save' /> Enregistreé <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                                </div>

                            </>   
                            : 
                            <PasDeResultat />}
                    </div>
                </>
                :
                <>
                    {/*<Carousel>
                        {imagesListe.map((data,index) => 
                                <div key={index}>
                                    <img src={`https://cdn.abyedh.com/images/Directory/${data.ImageLink}`} />
                                    <p className="legend"><Button onClick={() => RemoveImageFunc(data.ImageLink)}>Supprimer</Button></p>
                                </div>
                        )}
                    </Carousel>
                     {imagesListe.map((data,index) => <DisplayImageCard key={index} imageLink={data.ImageLink} />)} */}
                </>  
            }
                
        </>)
    }

    return (  <>
        <BackCard data={InputLinks.backCard.edit}/>
        <br />
        <div className='container-fluid'>
            <Tab menu={{  secondary: true, style: {overflowX : 'auto', overflowY : 'hidden', paddingBottom:'5px' }  }} panes={panes} />
            <br />
        </div>

    </>);
}


export default EditProfile;