const ASIL = {
    ptvGros:{
        colorTheme:'#6f858f',
        adsImageUrl:'storage.svg',
        systemTitle:'نظام إداراة نقاط البيع بالجملة ',
        systemUrl:'https://ptvgros.system.abyedh.tn/',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم .  كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير.  بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار)  التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvMagazin:{
        colorTheme:'#5e7968',
        adsImageUrl:'ptvente_shop.svg',
        systemTitle:'نظام إداراة نقاط  بيع التجزئة ',
        systemUrl:'https://magazin.system.abyedh.tn',
        adsText:'نظام إداراة المغازاة و نقاط البيع يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvFruiterie:{
        colorTheme:'#5e7968',
        adsImageUrl:'ptvente_shop.svg',
        systemTitle:'نظام إداراة نقاط  بيع الخضر ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط  بيع الخضر  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvBoucherie:{
        colorTheme:'#5e7968',
        adsImageUrl:'ptvente_shop.svg',
        systemTitle:'نظام إدارة المخابز  ',
        systemUrl:'#',
        adsText:'نظام إداراة المخابز  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvBoulengerie:{
        colorTheme:'#5e7968',
        adsImageUrl:'ptvente_shop.svg',
        systemTitle:'نظام إداراة نقاط  بيع اللحوم ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط بيع اللحوم  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvPatesserie:{
        colorTheme:'#5e7968',
        adsImageUrl:'ptvente_shop.svg',
        systemTitle:'نظام إداراة نقاط  بيع الحلويات ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط بيع الحلويات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvGrocery:{
        colorTheme:'#5e7968',
        adsImageUrl:'ptvente_shop.svg',
        systemTitle:'نظام إدارة البقالات ',
        systemUrl:'#',
        adsText:'نظام إداراة البقالات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    pharmacie:{
        colorTheme:'#6edb8d',
        adsImageUrl:'pharmacie.svg',
        systemTitle:'نظام إداراة الصيدليات ',
        systemUrl:'https://pharmacie.system.abyedh.tn',
        adsText:'نظام إداراة نقاط الصيدليات يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    librairie:{
        colorTheme:'#4c4a48',
        adsImageUrl:'librairie.svg',
        systemTitle:'نظام إداراة الكتبيات ',
        systemUrl:'#',
        adsText:'نظام إداراة الكتبيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    boutique:{
        colorTheme:'#b146c2',
        adsImageUrl:'boutique.svg',
        systemTitle:'نظام إداراة نقاط  بيع الملابس ',
        systemUrl:'https://boutique.system.abyedh.tn',
        adsText:'نظام إداراة نقاط بيع الملابس  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    quincaillerie:{
        colorTheme:'#406163',
        adsImageUrl:'chantier_quincaillerie.svg',
        systemTitle:' Quancaillerie نظام إدارة  ',
        systemUrl:'https://quincaillerie.system.abyedh.tn',
        adsText:'نظام إداراة Quancaillerie  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvElectro:{
        colorTheme:'#0078d7',
        adsImageUrl:'house_electro.svg',
        systemTitle:'نظام إداراة نقاط  بيع الالكترونيات ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط بيع الالكترونيات  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ptvMeuble:{
        colorTheme:'#0078d7',
        adsImageUrl:'house_meuble.svg',
        systemTitle:'نظام إداراة نقاط  بيع الاثاث ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط ابيع الاثاث  يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
   //13 (12-0)
    docteur:{
        colorTheme:'#085947',
        adsImageUrl:'docteur.svg',
        systemTitle:' نظام إدارة العيادات الطبية ',
        systemUrl:'#',
        adsText:' نظام إداراة العيادات يقدم لك مجموعة من الأدوات و الحلول الرقمية التي تساعدك علي أداء عملك بالشكل الممتاز , كما أنه يبقيك دائما متصلا مع مرضاك  للإطمئنان علي صحتهم و متابعة تطور حالتهم الصحية , بالإضافة إلي مجموعة من لوحات لاتحكم الموجهة لفريق العمل لتتابع أداء مهامهم بسهولة , فضلا  عن مجموعة متميزة من الأدوات التي تعينك علي تطوير عملك ',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    clinique:{
        colorTheme:'#04c6ce',
        adsImageUrl:'clinique.svg',
        systemTitle:' نظام إدارة المصحات ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    labo:{
        colorTheme:'#1dd3b0',
        adsImageUrl:'labo.svg',
        systemTitle:' نظام إدارة المخابر ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    centre:{
        colorTheme:'#52a3c9',
        adsImageUrl:'centre.svg',
        systemTitle:'  نظام إدارة المراكز الطبية ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //17 (16-0)
    garderie:{
        colorTheme:'#cf208f',
        adsImageUrl:'garderie.svg',
        systemTitle:'نظام  إدارة رياض الأطفال',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    autoecole:{
        colorTheme:'#1caaa8',
        adsImageUrl:'autoecole.svg',
        systemTitle:'نظام مدرسة تعليم سياقة ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    ecole:{
        colorTheme:'#4a9998',
        adsImageUrl:'ecole.svg',
        systemTitle:'نظام إداراة المدارس الخاصة ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    lycee:{
        colorTheme:'#4a9998',
        adsImageUrl:'lycee.svg',
        systemTitle:'نظام إدارة المعاهد الخاصة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    universite:{
        colorTheme:'#4a9998',
        adsImageUrl:'universite.svg',
        systemTitle:'نظام إداراة الجامعات الخاصة ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //22 (21-0)
    restaurant:{
        colorTheme:'#35ac67',
        adsImageUrl:'restaurant.svg',
        systemTitle:'نظام إداراة المطاعم ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    cafe:{
        colorTheme:'#363636',
        adsImageUrl:'cafe.svg',
        systemTitle:'نظام إداراة المقاهي و قاعات الشاي',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    hotel:{
        colorTheme:'#247cc7',
        adsImageUrl:'hotel.svg',
        systemTitle:'نظام إداراة الفنادق و النزل',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //25 (24-0)
    transporteur:{
        colorTheme:'#be6e70',
        adsImageUrl:'transporteur.svg',
        systemTitle:'نظام إداراة شركات نقل البظائع',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    vg_agence:{
        colorTheme:'#ce8c64',
        adsImageUrl:'vg-agence.svg',
        systemTitle:'نظام إداراة وكالات الأسفار',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //27 (26-0)
    samsar:{
        colorTheme:'#455b7a',
        adsImageUrl:'samsar.svg',
        systemTitle:'نظام إداراة مكتب وسيط عقاري',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    impremerie:{
        colorTheme:'#4c4a48',
        adsImageUrl:'impremerie.svg',
        systemTitle:'نظام إداراة المطابع',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    avocat:{
        colorTheme:'#7a8890',
        adsImageUrl:'avocat.svg',
        systemTitle:'نظام إداراة مكتب محامي',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    comptable:{
        colorTheme:'#a77a51',
        adsImageUrl:'comptable.svg',
        systemTitle:'نظام إداراة مكتب محاسب',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //31 (30-0)
    handmade_menuisier:{
        colorTheme:'#cdb556',
        adsImageUrl:'handmade_menuisier.svg',
        systemTitle:'نظام إداراة ورشة نجارة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    handmade_forgeron:{
        colorTheme:'#cdb556',
        adsImageUrl:'handmade_forgeron.svg',
        systemTitle:'نظام إداراة ورشة حدادة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    handmade_cristal:{
        colorTheme:'#cdb556',
        adsImageUrl:'handmade_cristal.svg',
        systemTitle:'نظام إداراة ورشة تركيب بلور',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    handmade_marbre:{
        colorTheme:'#cdb556',
        adsImageUrl:'handmade_marbre.svg',
        systemTitle:'نظام إداراة ورشات الرخام',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    handmade_electricien:{
        colorTheme:'#cdb556',
        adsImageUrl:'handmade_electricien.svg',
        systemTitle:'نظام إداراة شركات الكهرباء',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    handmade_peinture:{
        colorTheme:'#cdb556',
        adsImageUrl:'handmade_peinture.svg',
        systemTitle:'نظام إداراة شركات  الدهان',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //37 (36-0)
    chantier_contracteur:{
        colorTheme:'#406163',
        adsImageUrl:'chantier_contracteur.svg',
        systemTitle:'نظام إداراة شركات المقاولة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    chantier_architecture:{
        colorTheme:'#406163',
        adsImageUrl:'chantier_architecture.svg',
        systemTitle:'نظام إداراة مكتب مهندس معماري',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
   //39 (38-0)
    car_parking:{
        colorTheme:'#2d4664',
        adsImageUrl:'car_parking.svg',
        systemTitle:'Parking نظام إداراة ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    car_qiosque:{
        colorTheme:'#2d4664',
        adsImageUrl:'car_qiosque.svg',
        systemTitle:' Qiosque نظام إداراة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    car_location:{
        colorTheme:'#2d4664',
        adsImageUrl:'car_location.svg',
        systemTitle:'نظام إداراة شركات كراء السيارات',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //42 (41-0)
    wedding_photographe:{
        colorTheme:'#c30052',
        adsImageUrl:'wedding_photographe.svg',
        systemTitle:'نظام إداراة إستديو تصوير',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    wedding_salon_marriage:{
        colorTheme:'#c30052',
        adsImageUrl:'wedding_salon_marriage.svg',
        systemTitle:'نظام إداراة قاعة أفراح و مؤتمرات',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    wedding_fourniture_marriage:{
        colorTheme:'#c30052',
        adsImageUrl:'wedding_fourniture_marriage.svg',
        systemTitle:'نظام إداراة نقاط  بيع لوازم الأفراح ',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    coiffure:{
        colorTheme:'#0b97bf',
        adsImageUrl:'coiffure.svg',
        systemTitle:'نظام إداراة قاعات الحلاقة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //46 (45-0)
    gym:{
        colorTheme:'#7aadab',
        adsImageUrl:'gym.svg',
        systemTitle:'نظام إداراة قاعات الرياضة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    pyscine:{
        colorTheme:'#009fe3',
        adsImageUrl:'pyscine.svg',
        systemTitle:'نظام إداراة المسابح',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    stade:{
        colorTheme:'#587e6d',
        adsImageUrl:'stade.svg',
        systemTitle:'نظام إداراة الملاعب الرياضية',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    art_cinema:{
        colorTheme:'#2c7177',
        adsImageUrl:'art_cinema.svg',
        systemTitle:'نظام إداراة قاعات السنما',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    art_theatre:{
        colorTheme:'#2c7177',
        adsImageUrl:'art_theatre.svg',
        systemTitle:'نظام إداراة قاعات المسرح',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    //51 (50-0)
    farm:{
        colorTheme:'#72bcc7',
        adsImageUrl:'champ.svg',
        systemTitle:'نظام إداراة الضيعات الفلاحية',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    },
    socite:{
        colorTheme:'#32a6c9',
        adsImageUrl:'socite.svg',
        systemTitle:'نظام إداراة الشركات القابضة',
        systemUrl:'#',
        adsText:'نظام إداراة نقاط البيع بالجملة يساعدك علي مراقبة و التحكم في مخزون المنتجات (stock) و متابعة فواتيرك و الإتصال بعملاءك و متابعة طلباتهم . كما يقدم لك واجهة رسومية (caisse) لتسجيل المبيعات و طباعة الفواتير. بالإضافة إلي مجموعة من الأدوات الإبداعية (مثل واجهة إستخراج الأسعار) التي تساعدك علي أداء عملك بجودة أفضل',
        prosList:[
            {id:1, title:'متصل', color:'#4287f5', icon:'link-45deg', text:'يبقيك النظام في حالة إتصال دائم مع عملائك من خلال دليل شامل و تفاعلي يخول لهم التواصل معك بطريقة ذكية كما يبقيك متصل بكامل فريق العمل من خلال لوحات تحكم خاصة بهم لمتباعة أعمالهم و مراقبة مهامهم'},
            {id:2, title:'سهل', color:'#5dd492', icon:'emoji-smile', text:'يهدف النظام أن يكون بسيطا ولكن فعال في نفس الوقت و ذلك من خلال تنضيمة و تبويبه بطريقة بسيطة في نسخته الحالية'},
            {id:3, title:'معين', color:'#8923e8', icon:'arrows-move', text:'لا يكتفي النظام بمدك بأدوات الإدارة فقط بل يسعي ليكون عونا لك في أداء عملك من خلال إدوات إضافية تساعدك و تحسن من معرفتك بعملك مثلا كقاموس الأدوية و موسوعة الأمراض و مجسم ثلاثي الأبعاد لجسم الإنسان و غيرها ...'},
            {id:4, title:'متطور', color:'#5d6360', icon:'arrow-repeat', text:'نظام أبيض هو نظام سحابي و ليس نظام مثبت علي الحاسوب الشخصي لذلك يعمل فريق عمل أبيض المبدع علي تطويره و جعله مناسبا و مريحا و سهل الإستخدام ليكون مختلفا عن الأنظمة الكلاسكية "الجامدة"'},
            {id:5, title:' مدعوم', color:'#e6327a', icon:'info-circle-fill', text:'يأتي النظام مدعوما بدليل إستعمال شامل و إجابة دقيقة لكل الأسئلة التي يمكن أن تراودك عند بداية إستعمالك للنظام . لن تحتاج أن تتصل بخبير ليعلمك إستخدام النظام حيث يوجد شرح مفصل لكل وضائفه'},
            {id:6, title:'ذكي', color:'#d16200', icon:'lightbulb', text:'يقوم النضام بتجهيز خوارزمية لأتمتة العمليات الروتينية و المتكررة ما يعطي النضام صفة الذكاء و أتخاذ قرارات روتينية بنائا علي سلوك المستخدم'},
    
        ],
        prices:[
             {id:1, tag:'نسخة مفتوحة', name:'', price:'500', ready:true,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:false},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:false},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال المتوسطة', name:'', price:'780', ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:false},
                {id:9, text:' حفض آلي للبيانات', checked:false},
             ]},
             {id:1, tag:'للاعمال الكبري', name:'', price:'1020',  ready:false,
             pos:[
                {id:1, text:' 2 أشهر مجانية لتجريب النظام', checked:true},
                {id:2, text:' عدد غير محدود من الطلبات', checked:true},
                {id:3, text:' ضهور مجاني في الدليل', checked:true},
                {id:4, text:' معرف وحيد (PID)', checked:true},
                {id:5, text:' أدوات رقمية متنوعة و مجانية', checked:true},
                {id:6, text:'  إمكانية الربط بأجهزة أبيض الذكية', checked:true},
                {id:7, text:' إمكانية الربط بالبطاقة البنكية', checked:true},
                {id:8, text:' تحليل ذكي و ألي للبيانات', checked:true},
                {id:9, text:' حفض آلي للبيانات', checked:true},
             ]},
        ]
    }
   //53 (52-0)

}

export default ASIL