import React, {useEffect,useState}  from 'react';
import GConf from '../../AssetsM/generalConf';
import SubNav from '../Assets/subNav';
import { _ } from "gridjs-react";
import axios from 'axios';
import {Fade } from 'react-reveal';
import SKLT from '../../AssetsM/usedSlk'
import TableGrid from '../Assets/tableGrid';
import TableImage from '../Assets/tableImg';
import GoBtn from '../Assets/goBtn';
import { Button , Divider, Icon, Label} from 'semantic-ui-react';
import { NavLink, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

function OtherPage() {
    /*################[Variable]###############*/
    let  [clientList, setClientList] = useState([SKLT.TableSlt]); 
    let navigate = useNavigate();
    let Offline = JSON.parse(localStorage.getItem(`${GConf.PID}_Offline`));
    const colors = [
        'red',
        'orange',
        'yellow',
        'olive',
        'green',
        'teal',
        'blue',
        'violet',
        'purple',
        'pink',
        'brown',
        'grey',
        'black',
    ]

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
    // axios.post(`${GConf.ApiLink}/fournisseur`, {
    //     PID : GConf.PID,
    //   })
    //   .then(function (response) {
    //     console.log(response.data)
    //      let testTable = []
    //       response.data.map( (getData) => testTable.push([
    //     _(<AvatarCard lettre={capitalizeFirstLetter(getData.Four_Name)} />),
    //      getData.Four_Code_Fiscale,
    //      getData.Four_Name,
    //      getData.Four_Phone,
    //      getData.Four_Adress,
    //      getData.Articles_Genre,
    //      getData.Jour_Periodique,
    //      _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/S/fs/info/${getData.Four_ID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
    //     ],))
    //     setClientList(testTable)
    //   }).catch((error) => {
    //     if(error.request) {
    //       toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment des ancien Camion  </div></>, GConf.TostInternetGonf)   
    //       setClientList(Offline.camion)
    //     }
    //   });
    }, [])


    /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const  capitalizeFirstLetter = (string) =>{
        return (string.charAt(0).toUpperCase() + string.slice(1)).charAt(0);
    }
    /*################[Card]###############*/
     const AvatarCard = (props) =>{
            return(<>
                <Label size='massive' circular color={colors[Math.floor(Math.random() * 10)]} key={1}>
                    <h3>{props.lettre}</h3>
                </Label>
                
            </>)
    }

    const LinkCardCard = (props) =>{
        return(<>
            <div className='card card-body border-div mb-2 shadow-sm '>
                <NavLink to={`/S/th/${props.link}`}>
                    <div className={`bi bi-${props.icon} bi-lg text-center`} style={{color: props.color}} ></div>
                    <h2 className='text-center text-secondary mt-1'>{props.name}</h2>
                </NavLink>
            </div> 
        </>)
}

    return ( 
        <>
            <Fade>
                <br />
                <h2>ADMINISTRATION </h2>
                <br />
                <div className='row'>
                    <div className='col-6 col-md-2'><LinkCardCard name='marketing' icon='signpost-2-fill' color='#eb4034' link='marketing' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Concurant' icon='share-fill' color='#FEE715' link='concurrent' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Relation' icon='person-fill' color='#F96167' link='relation' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Ventes' icon='cart-check-fill' color='#00246B' link='ventes' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Messages' icon='chat-left-quote' color='#2C5F2D' link='messages' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Planning' icon='card-checklist' color='#A7BEAE' link='planning' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Finance' icon='cash-coin' color='#B85042' link='finance' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Stat' icon='bar-chart-line-fill' color='' link='stat' /></div>
                </div>

                
                {/* <h2>TOOLS</h2>
                <div className='row'>
                    <div className='col-6 col-md-2'><LinkCardCard name='Serveur' icon='hdd-rack-fill' color='' link='serveur' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='BackUp' icon='layer-backward' color='' link='backup' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Messages' icon='chat-left-quote' color='' link='messages' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Planning' icon='card-checklist' color='' link='messages' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Finance' icon='cash-coin' color='' link='messages' /></div>
                    <div className='col-6 col-md-2'><LinkCardCard name='Stat' icon='bar-chart-line-fill' color='' link='messages' /></div>

                </div> */}
                <br />
                
            </Fade>         
        </>);
}

export default OtherPage;