const ADIL = {
        docteur : {directoryTable:'01_docteur', RequestTable:'01_docteur_setting', SearchByGenre: true,  FavoriteGenre:'Sante'},
        infirmier : {directoryTable:'01_infermerie', RequestTable:'01_infermerie_setting', SearchByGenre: false,  FavoriteGenre:'Sante'},
        pharmacie : {directoryTable:'01_pharmacie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        clinique : {directoryTable:'01_clinique', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sante'},
        labo : {directoryTable:'01_laboratoire', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sante'},
        centreMD : {directoryTable:'01_centre', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sante'},
        admin_s_hospital : {directoryTable:'01a_hopitale', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_s_csb : {directoryTable:'01a_centre_sb', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},

        garderie : {directoryTable:'02_garderie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Education'},
        ecole : {directoryTable:'02_ecole', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Education'},
        lycee : {directoryTable:'02_college_lycee', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Education'},
        universite : {directoryTable:'02_universite', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Education'},
        formation : {directoryTable:'02_formation', RequestTable:'02_formation_setting', SearchByGenre: false,  FavoriteGenre:'Education'},
        librairie : {directoryTable:'02_librairie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Education'},
        admin_e_centre : {directoryTable:'02a_centref', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_e_biblio : {directoryTable:'02a_bibliotheque', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_e_ecole : {directoryTable:'02a_ecole', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_e_lycee : {directoryTable:'02a_lycee', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_e_universite : {directoryTable:'02a_universite', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_e_ss : {directoryTable:'02_garderie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},

        transporteur : {directoryTable:'03_transporteur', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Transpo'},
        autoecole : {directoryTable:'02_autoecole', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Transpo'},
        taxi : {directoryTable:'03_taxi', RequestTable:'03_taxi_setting', SearchByGenre: false,  FavoriteGenre:'Transpo'},
        louage : {directoryTable:'03_louage', RequestTable:'03_taxi_setting', SearchByGenre: false,  FavoriteGenre:'Transpo'},

        cafe : {directoryTable:'05_cafe', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        restaurant : {directoryTable:'05_restaurant', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        magazin : {directoryTable:'05_magazin', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        epicerie : {directoryTable:'05_pv_epicerie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        boulengerie : {directoryTable:'05_pv_boulangerie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        boucherie : {directoryTable:'05_pv_boucheries', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        fruiterie : {directoryTable:'05_pv_fruiterie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        patesserie : {directoryTable:'05_pv_patisserie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        

        electromenager : {directoryTable:'04_h_electromenager', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        meublerie : {directoryTable:'04_h_meubles', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        lavage : {directoryTable:'04_h_lavage', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        location : {directoryTable:'04_v_location', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        parking : {directoryTable:'04_v_parking', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        qiosque : {directoryTable:'04_v_qiosque', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        mecanicien : {directoryTable:'04_v_mecanicien', RequestTable:'01_mecanicien_setting', SearchByGenre: false,  FavoriteGenre:'Life'},

        courtier : {directoryTable:'09_c_courtier', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        contracteur : {directoryTable:'09_c_contracteur', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        architecture : {directoryTable:'09_c_architecture', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        quincaillerie : {directoryTable:'09_c_quincaillerie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        forgeron : {directoryTable:'09_h_forgeron', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        menuisier : {directoryTable:'09_h_menuisier', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        peinture : {directoryTable:'09_h_peinture', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        electricien : {directoryTable:'09_h_electricien', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        plombier : {directoryTable:'09_h_plombier', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        cristalerie : {directoryTable:'09_h_cristalerie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},
        marbrerie : {directoryTable:'09_h_marbrerie', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Construct'},

        coiffure : {directoryTable:'04_coiffure', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        boutique : {directoryTable:'04_boutique', RequestTable:'01_docteur_setting', SearchByGenre: true,  FavoriteGenre:'PTVente'},
        salle_marriage : {directoryTable:'04_m_salon_marriage', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        salon_marriage : {directoryTable:'04_m_salon_marriage', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        orchestre : {directoryTable:'04_m_orchestre', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        chef : {directoryTable:'04_m_chef', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        photographe : {directoryTable:'04_m_photographe', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        fourniture_marriage : {directoryTable:'04_m_fourniture_marriage', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        bijouterie : {directoryTable:'04_m_bijoux', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},

        gym : {directoryTable:'06_sport_salle', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        pyscine : {directoryTable:'06_pycine', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        stade : {directoryTable:'06_stade', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        cinema : {directoryTable:'06_cinema', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        theatre : {directoryTable:'06_theatre', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        musee : {directoryTable:'06_musee', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        

        admin_c_mj : {directoryTable:'06a_jeune_ma', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        admin_c_mc : {directoryTable:'06a_culture_ma', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},
        admin_c_me : {directoryTable:'06a_enfant_ma', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Sport'},

        avocat : {directoryTable:'10_avocat', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Other'},

        depot : {directoryTable:'08_vente_en_gros', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'PTVente'},
        comptable : {directoryTable:'08_comptable', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Other'},
        bank : {directoryTable:'08_bank', RequestTable:'01_bank_setting', SearchByGenre: false,  FavoriteGenre:'Other'},
        admin_f_poste : {directoryTable:'08a_poste', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_f_rf : {directoryTable:'08a_recipe', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},

        hotels : {directoryTable:'07_hotels', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        vg_agence : {directoryTable:'07_agence', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Life'},
        
        veterinaire : {directoryTable:'11_veterinaire', RequestTable:'11_veterinaire_setting', SearchByGenre: false,  FavoriteGenre:'Agricole'},
        fourragerie : {directoryTable:'11_fourragerie', RequestTable:'11_fourragerie_setting', SearchByGenre: false,  FavoriteGenre:'Agricole'},
        agritools : {directoryTable:'11_agritools', RequestTable:'11_agritools_setting', SearchByGenre: false,  FavoriteGenre:'Agricole'},
        agri_farmer : {directoryTable:'11_agri_farmer', RequestTable:'11_agritools_setting', SearchByGenre: false,  FavoriteGenre:'Agricole'},

        admin_a_mu : {directoryTable:'00_municipalite', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_a_police : {directoryTable:'00_police', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_a_ar : {directoryTable:'00_admin_regionale', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_a_court : {directoryTable:'00_cour', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        admin_a_mosq : {directoryTable:'00_mosquee', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
        ambassade : {directoryTable:'00_ambassade', RequestTable:'01_docteur_setting', SearchByGenre: false,  FavoriteGenre:'Admin'},
}
export default ADIL