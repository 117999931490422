import React, {useEffect,useState}  from 'react';
import GConf from '../../AssetsM/generalConf';
import SubNav from '../Assets/subNav';
import { _ } from "gridjs-react";
import axios from 'axios';
import {Fade } from 'react-reveal';
import SKLT from '../../AssetsM/usedSlk'
import TableGrid from '../Assets/tableGrid';
import TableImage from '../Assets/tableImg';
import GoBtn from '../Assets/goBtn';
import { Button , Icon, Label} from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

function ClientRequest() {
       /*################[Variable]###############*/
       const  [clientList, setClientList] = useState([SKLT.TableSlt]); 
       const navigate = useNavigate();
       let Offline = JSON.parse(localStorage.getItem(`${GConf.PID}_Offline`));
       const colors = [
           'red',
           'orange',
           'yellow',
           'olive',
           'green',
           'teal',
           'blue',
           'violet',
           'purple',
           'pink',
           'brown',
           'grey',
           'black',
       ]
   
       /*################[UseEffect]###############*/
       useEffect(() => {
           axios.post(`${GConf.ApiLink}/clients/systemrequest`,{
               PID: GConf.PID
           })
           .then(function (response) {
            console.log(response.data)
               let testTable = []
               response.data.slice().reverse().map( (getData) => testTable.push([
                //    _(<img src={`https://cdn.abyedh.com/Assets/images/p_pic/${getData.PictureId}.gif`} className='img-responsive' width='40px' height='40px'  />),
                _(<AvatarCard associated={getData.PID != 0} lettre={capitalizeFirstLetter(getData.Genre)} />),
                getData.Req_ID,
                getData.PID,
                getData.Genre,
                new Date(getData.Req_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
                getData.Req_Time,
                getData.Req_State,
               _(< a className='rounded-pill p-1 ps-2 bg-system-btn' size='mini' href={`https://abyedh.tn/S/P/${getData.Genre}/${getData.PID}`}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></a>)
               ],))
               setClientList(testTable)
           }).catch((error) => {
               if(error.request) {
                 toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment des client sur votre ordinateur  </div></>, GConf.TostInternetGonf)   
                 let testTable = []
               Offline.client.map( (getData) => testTable.push([
               _(<AvatarCard lettre={capitalizeFirstLetter(getData.Name)} />),
               getData.Name,
               getData.Code_Fiscale,
               getData.Phone,
               _(<>{getData.Gouv} , {getData.Deleg} </>),
               getData.Adress,
               _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/S/cl/demande/info/${getData.PK}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
               ],))
               setClientList(testTable)
               }
             });
       }, [])
       
       /*#########################[Function]##################################*/
       const NavigateFunction = (link) => {  navigate(link) }
       const  capitalizeFirstLetter = (string) =>{
           return (string.charAt(0).toUpperCase() + string.slice(1)).charAt(0);
       }
       /*################[Card]###############*/
        const AvatarCard = (props) =>{
               return(<>
                <span className='notification'>
                    <Label size='massive' circular color={colors[Math.floor(Math.random() * 10)]} key={1}>
                       <h3>{props.lettre}</h3>
                   </Label>
                    <span className={`${props.associated ? ' badged ' : 'd-none'}`}></span>
                </span>
                   
                   
               </>)
        }
       return (<>
           <Fade>
               <TableGrid tableData={clientList} columns={GConf.TableHead.clientReq} />
           </Fade>
       </>);
   }

export default ClientRequest;