import React, {useEffect,useState}  from 'react';
import axios from 'axios';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import { toast } from 'react-toastify';
import { Segment , Icon, Input, Button, Loader, Dropdown, Dimmer, Select} from 'semantic-ui-react';
import 'react-circular-progressbar/dist/styles.css';
import GConf from '../../../AssetsM/generalConf';
import { localeData } from 'moment/moment';

function UploadeCamionPage() {

    /*#########################[Const]##################################*/
    const [isDisabled , setIsDisabled] = useState(false)
    const [loaderState, setLS] = useState(false)
    const [loadingPage, setLoadingP] = useState(false)
    const [delegList ,setDelegList] = useState([])
    const [gouv ,setGouv] = useState('')
    const [deleg ,setDeleg] = useState('')
    const [tag , setTag] = useState('')
    let camData = JSON.parse(localStorage.getItem(`Admin_Dir_LocalD`));
    let [Offline, setOffline] = useState(JSON.parse(localStorage.getItem(`Admin_Dir_Offline`))); 
    const options = [
        { key: 1, text: 'docteur', value: 'docteur'},
        { key: 2, text: 'pharmacie', value: 'pharmacie'},
        { key: 3, text: 'clinique', value: 'clinique'},
        { key: 4, text: 'labo', value: 'labo'},
        { key: 5, text: 'centremedi', value: 'centreMD'},
        { key: 6, text: 'Hopitale', value: 'admin_s_hospital'},
        { key: 7, text: 'centre de soins de base', value: 'admin_s_csb'},
        { key: 8, text: 'garderie', value: 'garderie' },
        { key: 9, text: 'ecole Priveé', value: 'ecole' },
        { key: 10, text: 'Lycee Priveé', value: 'lycee' },
        { key: 11, text: 'Universite Priveé', value: 'universite' },
        { key: 12, text: 'Impremerie', value : 'impremerie' },
        { key: 13, text: 'Librairie',value : 'librairie' },
        { key: 14, text: 'centre de formation', value: 'admin_e_centre' },
        { key: 15, text: 'bibliothéque ', value: 'admin_e_biblio' },
        { key: 16, text: 'Transporteur',  value: 'transporteur' },
        { key: 17, text: 'Autoecole', value: 'autoecole' },
        { key: 18, text: 'Cafe', value: 'cafe' },
        { key: 19, text: 'Restaurant', value: 'restaurant' },
        { key: 20, text: 'Magazin', value: 'ptvente_shop' },
        { key: 21, text: 'Boulengerie', value: 'ptvente_boulengerie' },
        { key: 22, text: 'Boucherie', value: 'ptvente_viande' },
        { key: 23, text: 'Fruiterie', value: 'ptvente_fruit' },
        { key: 24, text: 'Patesserie', value: 'ptvente_patesserie' },
        { key: 25, text: 'Epecerie', value: 'ptvente_small_shop' },
        { key: 26, text: 'Electromenager', value: 'house_electro' },
        { key: 27, text: 'Meuble', value: 'house_meuble' },
        { key: 28, text: 'Location voiture', value: 'car_location' },
        { key: 29, text: 'Parking', value: 'car_parking' },
        { key: 30, text: 'Samsar ', value: 'samsar' },
        { key: 31, text: 'Contracteur', value: 'chantier_contrateur' },
        { key: 32, text: 'Architequte', value: 'chantier_architecture' },
        { key: 33, text: 'Quicaillerie', value: 'chantier_quincaillerie' },
        { key: 34, text: 'Forgeron', value: 'handmade_forgeron' },
        { key: 35, text: 'menuisier', value: 'handmade_menuisier' },
        { key: 36, text: 'peinture', value: 'handmade_peinture' },
        { key: 37, text: 'electricien', value: 'handmade_electricien' },
        { key: 38, text: 'coiffure', value: 'coiffure' },
        { key: 39, text: 'boutique', value: 'boutique' },
        { key: 40, text: 'Salon marriage', value: 'wedding_salon_marriage' },
        { key: 41, text: 'Orchestre', value: 'wedding_orchestre' },
        { key: 42, text: 'Chef Cuisine', value: 'wedding_chef' },
        { key: 43, text: 'Photographe', value: 'wedding_photographe' },
        { key: 44, text: 'Vente fourniture marriage', value: 'wedding_fourniture_marriage' },
        { key: 45, text: 'Bijouterie', value: 'wedding_bijoux' },
        { key: 46, text: 'Salle de sport',  value: 'gym' },
        { key: 47, text: 'Piscine',  value: 'pyscine' },
        { key: 48, text: 'Stade',  value: 'stade' },
        { key: 49, text: 'Cinema',  value: 'art_cinema' },
        { key: 50, text: 'Theatre', value: 'art_theatre' },
        { key: 51, text: 'Musee',  value: 'art_musee' },
        { key: 52, text: 'maison des jeunes ',  value: 'admin_c_mj' },
        { key: 53, text: 'maison de culture ', value: 'admin_c_mc' },
        { key: 54, text: 'Avocat',  value: 'avocat' },
        { key: 55, text: 'Vnete en gros', value: 'storage' },
        { key: 56, text: 'Comptable',  value: 'comptable' },
        { key: 57, text: 'socite', value: 'socite' },
        { key: 58, text: 'Buerux de Poste ', value: 'admin_f_poste' },
        { key: 59, text: 'Hotels',  value: 'hotels' },
        { key: 60, text: 'travel', value: 'vg_agence' },
        { key: 61, text: 'Municipaliteé',  value: 'admin_a_mu' },
        { key: 62, text: 'Agence de Police', value: 'admin_a_police' },
        { key: 63, text: 'Admin Regionale ',  value: 'admin_a_ar' },
        { key: 64, text: 'Court ',  value: 'admin_a_court' },


    ]

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
        // axios.post(`${GConf.ApiInputLink}/update`, {
        //     forPID : camData.PID,
        //     camId : camId
        // }).then(function (response) {
        //     setStock(response.data[0].stock)
        //     setFactures(response.data[0].facture)
        //     setClients(response.data[0].client)
        //     setLoadingP(false)
        // }).catch((error) => {
        //     if(error.request) {
        //       toast.error(<><div><h5>Probleme de Connextion</h5> Vous ne pouver pas mettre à jour vos donneé maintenant</div></>, GConf.TostInternetGonf) 
        //       setStock([])
        //       setFactures([])
        //       setIsDisabled(true)
        //       setLoadingP(false)
        //     }
        // });
    }, [])

    /*#########################[Functions]##################################*/
    const GetDelegList = (value) =>{
        setGouv(value)
        const found = GConf.abyedhMap.Deleg.filter(element => element.tag === value)
        setDelegList(found)
    }
    const UpdateLocalMap = () =>{
        if (!gouv) {toast.error("Entrer Gouvernorat !", GConf.TostErrorGonf)}
        else if (!deleg) {toast.error("Entrer Delegation  !", GConf.TostErrorGonf)}
        else if (!tag) {toast.error("Entrer Tag  !", GConf.TostErrorGonf)}
        else{
            Offline.localData = [{gouv:gouv, deleg:deleg, tag: tag}]
            localStorage.setItem(`Admin_Dir_Offline`, JSON.stringify(Offline));
            setOffline(JSON.parse(localStorage.getItem(`Admin_Dir_Offline`)))
            toast.success("Modifieé !", GConf.TostSuucessGonf)
        }
        
    }

    const DeleteFromOffline = (targetTable, targetElm) =>{
        console.log(Offline[targetTable][targetElm])
        Offline[targetTable].splice(targetElm,1)
        localStorage.setItem(`Admin_Dir_Offline`,  JSON.stringify(Offline));
    }
    const SaveFactureFunc = (targetIndex) => {
        setLS(true)
        let factureSelected = Offline.ajoutee[targetIndex]
        console.log(factureSelected)
        axios.post(`${GConf.ApiInputLink}/nv/ajouter`, {
            forPID : camData.PID,
            factureD: factureSelected,
        })
        .then(function (response) {
            if(response.status = 200) {
                toast.success("Facture Enregistreé !", GConf.TostSuucessGonf)
                setLS(false)
                Offline.ajoutee.splice(targetIndex,1)
                localStorage.setItem(`Admin_Dir_Offline`,  JSON.stringify(Offline));
            }
            else{
                toast.error('Erreur!  esseyez de nouveaux', GConf.TostSuucessGonf)
                setLS(false)
            }
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>   </div></>, GConf.TostInternetGonf)  
              setLS(false) 
            }
          });
        
    }
    const SaveClientFunc = (targetIndex) => {
        setLS(true)
        let clientSelected = Offline.clientToSave[targetIndex]
        axios.post(`${GConf.ApiLink}/client/ajouter`, {
            tag : GConf.SystemTag,
            clientD : clientSelected,
        }).then(function (response) {
            if(response.data.affectedRows) {
                toast.success("Client Ajouter !", GConf.TostSuucessGonf)
                setLS(false)
                Offline.modifiee.splice(targetIndex,1)
                localStorage.setItem(`Admin_Dir_Offline`,  JSON.stringify(Offline));
            }
            else{
                toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                setLS(false)
                }
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>   </div></>, GConf.TostInternetGonf)  
              setLS(false) 
            }
          });
        
    }
    const SaveDepenseFunc = (targetIndex) => {
        setLS(true)
        let CamionSelected = Offline.camionToSave[targetIndex]
        axios.post(`${GConf.ApiLink}/camions/ajouter`, {
            tag : GConf.SystemTag,
            camionD : CamionSelected,
        }).then(function (response) {
            console.log(response.data)
            if(response.data.affectedRows) {
                toast.success("Camion Ajouteé !", GConf.TostSuucessGonf)
                setLS(false)
                Offline.camionToSave.splice(targetIndex,1)
                localStorage.setItem(`Admin_Dir_Offline`,  JSON.stringify(Offline));
            }
            else {
                    toast.error('Erreur esseyez de nouveaux', GConf.TostSuucessGonf)
                    setLS(false)
                }
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5>   </div></>, GConf.TostInternetGonf)  
              setLS(false) 
            }
          });
        
    }
   
    /*#########################[Card]##################################*/
    const SetDefaultData = (props) =>{
        return(<>
        <div className='col-12 col-lg'>
            <div className='card card-body shadow-sm mb-2'>
                <div className='text-left mb-2 '>
                    <b className='text-danger'>Mettre a jour les donneé locale</b>
                    { Offline.localData.length != 0 ? 
                        <div className='mb-2'>{Offline.localData[0].gouv} | {Offline.localData[0].deleg} | {Offline.localData[0].tag} </div>
                        :
                        <></>
                    }
 
                </div>
                 
                <Select placeholder='Gouvernorat' className='mb-2 shadow-sm' options={GConf.abyedhMap.Gouv} value={gouv} onChange={(e, { value }) => GetDelegList(value)} />
                <Select placeholder='Delegation ' className='mb-2 shadow-sm' value={deleg} options={delegList} onChange={(e, { value }) => setDeleg(value)} />
                <Select placeholder='Tag ' className='shadow-sm' value={tag} options={options} onChange={(e, { value }) => setTag(value)} />
                <br /> 
                <div className='text-center'><Button size='tiny' disabled={isDisabled} fluid className=' rounded-pill bg-system-btn' icon onClick={() => UpdateLocalMap()}>  <Icon name='retweet' /> Mettre à Jour </Button></div> 
            </div> 
        </div>
        </>)
    }
    const PasDeResult = (props) =>{
        return(<>
            <div className='card-body'>
                <div className='row'>
                <div className='col-12 text-center align-self-center'> <img src='https://assets.ansl.tn/Images/usful/empty-logo.svg' width='50%'  height='100px' /></div>
                    <div className='col-12 text-center align-self-center text-secondary'><b>Il y'a Pas de {props.genre} a enregistreé </b></div>
                </div>
                
            </div>
        </>)
    }
    const FactureTosave = (props) =>{
        return(<>
            <div className='card p-2 mb-3 shadow-sm'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row d-none'>
                            <div className='col-12 align-self-center'>client :  {props.data.Name}</div>
                            <div className='col-12 align-self-center'>Totale:  {props.data.Phone} </div>
                            <div className='col-12 align-self-center'>N articles :  {props.data.Adress} <small>articles</small> </div>
                            <div className='col-6 col-lg-2 text-start'> <Button size='tiny'   className=' rounded-pill bg-system-btn' icon onClick={(e) => SaveFactureFunc(props.offIndex)}>  <Icon name='save' /> Enregistré </Button> </div>
                            <div className='col-6 col-lg-1 text-end'> <Button size='tiny'    className=' rounded-pill bg-danger text-white' icon onClick={(e) => DeleteFromOffline('factureToSave', props.offIndex)}>  <Icon name='save' /></Button> </div>
                        </div> 
                    </div>
                </div>  
            </div>
        </>)
    }
    const ClientTosave = (props) =>{
        return(<>
            <div className='card p-2 mb-3 shadow-sm rounded-pill'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-2 align-self-center'>client :  {props.data.Code_Fiscale}</div>
                            <div className='col-2 align-self-center'>Totale:  {props.data.Name} </div>
                            <div className='col-2 align-self-center'>N articles :  {props.data.Phone} </div>
                            <div className='col-2 align-self-center'>Gouvernement : {props.data.Gouv} </div>
                            <div className='col-2 align-self-center'>Adresse ; {props.data.Adress} </div>
                            <div className='col-6 col-lg-2 text-start'> <Button size='tiny'   className=' rounded-pill bg-system-btn' icon onClick={(e) => SaveClientFunc(props.offIndex)}>  <Icon name='save' /> Enregistré </Button> </div>
                            <div className='col-6 col-lg-1 text-end'> <Button size='tiny'    className=' rounded-pill bg-danger text-white' icon onClick={(e) => DeleteFromOffline('clientToSave', props.offIndex)}>  <Icon name='save' /></Button> </div>
                        </div> 
                    </div>
                </div>  
            </div>
        </>)
    }
    const DepenseTosave = (props) =>{
        return(<>
            <div className='card p-2 mb-3 shadow-sm'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-12 align-self-center'>Genre :  {props.data.genre}</div>
                            <div className='col-12 align-self-center'>Valeur:  {props.data.valeur} </div>
                            <div className='col-12 align-self-center'></div>
                            <div className='col-12 align-self-center'></div>
                            <div className='col-6 col-lg-2 text-start'> <Button size='tiny'   className=' rounded-pill bg-system-btn' icon onClick={(e) => SaveClientFunc(props.offIndex)}>  <Icon name='save' /> Enregistré </Button> </div>
                            <div className='col-6 col-lg-1 text-end'> <Button size='tiny'    className=' rounded-pill bg-danger text-white' icon onClick={(e) => DeleteFromOffline('clientToSave', props.offIndex)}>  <Icon name='save' /></Button> </div>
                        </div> 
                    </div>
                </div>  
            </div>
        </>)
    }

    return (<>
        <BackCard data={InputLinks.backCard.up}/>
        <br />
        <Dimmer active={loaderState || loadingPage} page inverted style={{minHeight:'100% !important'}}>
            <Loader inverted>{loadingPage == true ? 'Chargemment' : 'Enregistremment'} </Loader>
        </Dimmer>
        <div className='container'>
            <br />
            <div className='row'>
                <SetDefaultData  />
            </div>
            <br />
            <br />
            <div className='p-2'>
               <h5 className='bg-danger d-inline-block p-2 rounded-pill text-white ps-3 pe-3'><span className='bi bi-receipt-cutoff'></span> Entreé à enregistreé : </h5>
               {Offline.ajoutee.length != 0 ? Offline.ajoutee.map( (data, index) => <FactureTosave key={index} data={data} offIndex={index} />) : <PasDeResult />}  

                <h5 className='bg-danger d-inline-block p-2 rounded-pill text-white ps-3 pe-3'><span className='bi bi-receipt-cutoff'></span> Modification à enregistreé : </h5>
               {Offline.modifiee.length != 0 ? Offline.modifiee.map( (data, index) => <ClientTosave key={index} data={data} offIndex={index} />) : <PasDeResult />}

               <h5 className='bg-danger d-inline-block p-2 rounded-pill text-white ps-3 pe-3'><span className='bi bi-receipt-cutoff'></span> Info Speciale à enregistreé : </h5>
               {Offline.positions.length != 0 ? Offline.positions.map( (data, index) => <DepenseTosave key={index} data={data} offIndex={index} />) : <PasDeResult />}

            </div>
        </div>
    </>);
}

export default UploadeCamionPage;