const REQUESTS ={
    docteur_rdv : {communicTable:'01_docteur_rdv', tagName:'docteur', RequestTable:{}},
    infirmier_rdv : {communicTable:'01_infirmier_rdv', tagName:'infirmier', RequestTable:{}},
    pharmacie_shop : {communicTable:'01_pharmacie_shop', tagName:'pharmacie', RequestTable:{}},
    pharmacie_rdv : {communicTable:'01_pharmacie_rdv', tagName:'pharmacie', RequestTable:{}},
    infirmier_rdv : {communicTable:'01_infirmier_rdv', tagName:'infirmier', RequestTable:{}},
    garderie_inscription : {communicTable:'02_garderie_inscription', tagName:'garderie', RequestTable:{}},
    garderie_souscription : {communicTable:'02_garderie_souscription', tagName:'garderie', RequestTable:{}},
    autoecole_inscrie : {communicTable:'03_autoecole_inscrie', tagName:'autoecole', RequestTable:{}},
    avocat_souscrire : {communicTable:'10_avocat_souscrire', tagName:'avocat', RequestTable:{}},
    avocat_rdv : {communicTable:'10_avocat_rdv', tagName:'avocat', RequestTable:{}},
    boutique_shop : {communicTable:'04_boutique_shop', tagName:'boutique', RequestTable:{}},
    //pv_boulangerie_shop : {communicTable:'05_boulangerie_shop', tagName:'boulengerie', RequestTable:{}},
    gym_souscription : {communicTable:'06_gym_souscription', tagName:'gym', RequestTable:{}},
    centre_inscrie : {communicTable:'01_centre_inscrie', tagName:'centreMD', RequestTable:{}},
    centre_reserver : {communicTable:'01_centre_reserver', tagName:'centreMD', RequestTable:{}},
    clinique_inscrie : {communicTable:'01_clinique_inscrie', tagName:'clinique', RequestTable:{}},
    clinique_reserver : {communicTable:'01_clinique_reserver', tagName:'clinique', RequestTable:{}},
  
    labo_inscrie : {communicTable:'01_labo_inscrie', tagName:'labo', RequestTable:{}},
    labo_rdv : {communicTable:'01_labo_rdv', tagName:'labo', RequestTable:{}},
 
    college_lycee_inscrie : {communicTable:'02_college_lycee_inscrie', tagName:'lycee', RequestTable:{}},
    college_lycee_souscrire : {communicTable:'02_college_lycee_souscrire', tagName:'lycee', RequestTable:{}},
    ecole_inscrie : {communicTable:'02_ecole_inscrie', tagName:'ecole', RequestTable:{}},
    ecole_souscrire : {communicTable:'02_ecole_souscrire', tagName:'ecole', RequestTable:{}},

    formation_inscription : {communicTable:'02_formation_inscription', tagName:'formation', RequestTable:{}},
    formation_souscription : {communicTable:'02_formation_souscription', tagName:'formation', RequestTable:{}},
    librairie_shop : {communicTable:'02_librairie_shop', tagName:'librairie', RequestTable:{}},
    universite_inscription : {communicTable:'02_universite_inscrie', tagName:'universite', RequestTable:{}},
    //universite_inscrie : {communicTable:'02_universite_inscrie', tagName:'universite', RequestTable:{}},
    universite_souscription : {communicTable:'02_universite_souscrire', tagName:'universite', RequestTable:{}},
    //universite_souscrire : {communicTable:'02_universite_souscrire', tagName:'universite', RequestTable:{}},
    autoecole_inscrie : {communicTable:'03_autoecole_inscrie', tagName:'autoecole', RequestTable:{}},
    louage_rdv : {communicTable:'03_louage_rdv', tagName:'louage', RequestTable:{}},
    taxi_rdv : {communicTable:'03_taxi_rdv', tagName:'taxi', RequestTable:{}},
    taxi_request : {communicTable:'03_taxi_request', tagName:'taxi', RequestTable:{}},
    transporteur_request : {communicTable:'03_transporteur_request', tagName:'transporteur', RequestTable:{}},
     
    coiffure_rdv : {communicTable:'04_coiffure_rdv', tagName:'coiffure', RequestTable:{}},
    coiffure_reserver : {communicTable:'04_coiffure_reserver', tagName:'coiffure', RequestTable:{}},
    electromenager_shop : {communicTable:'04_h_electromenager_shop', tagName:'electromenager', RequestTable:{}},
    lavage_demande : {communicTable:'04_lavage_demande', tagName:'lavage', RequestTable:{}},
    mecanicien_request : {communicTable:'04_mecanicien_request', tagName:'mecanicien', RequestTable:{}},
    meublerie_shop : {communicTable:'04_h_meubles_shop', tagName:'meublerie', RequestTable:{}},
    location_request : {communicTable:'04_location_request', tagName:'location', RequestTable:{}},
    bijouterie_shop : {communicTable:'04_bijouterie_shop', tagName:'bijouterie', RequestTable:{}},
    lingerie_demmande : {communicTable:'04_h_lavage_demande', tagName:'lingerie', RequestTable:{}},
    chef_reserver : {communicTable:'04_chef_reserver', tagName:'chef', RequestTable:{}},
    fourniture_marriage_location : {communicTable:'04_fourniture_marriage_location', tagName:'fourniture_marriage', RequestTable:{}},
    orchestre_reserver : {communicTable:'04_orchestre_reserver', tagName:'orchestre', RequestTable:{}},
    photographe_reserver : {communicTable:'04_photographe_reserver', tagName:'photographe', RequestTable:{}},
    salon_marriage_reserver : {communicTable:'04_salon_marriage_reserver', tagName:'salle_marriage', RequestTable:{}},
    parking_reserver : {communicTable:'04_parking_reserver', tagName:'parking', RequestTable:{}},
    parking_souscrire : {communicTable:'04_parking_souscrire', tagName:'parking', RequestTable:{}},
     
    qiosque_lavage : {communicTable:'04_qiosque_lavage', tagName:'qiosque', RequestTable:{}},
    qiosque_request : {communicTable:'04_qiosque_request', tagName:'qiosque', RequestTable:{}},
    cafe_commande : {communicTable:'05_cafe_commande', tagName:'cafe', RequestTable:{}},
    cafe_reservation : {communicTable:'05_cafe_reservation', tagName:'cafe', RequestTable:{}},
    magazin_commande : {communicTable:'05_magazin_commande', tagName:'magazin', RequestTable:{}},
    epicerie_shop : {communicTable:'05_epicerie_shop', tagName:'epicerie', RequestTable:{}},
    boucheries_shop : {communicTable:'05_boucheries_shop', tagName:'boucherie', RequestTable:{}},
    boulangerie_shop : {communicTable:'05_boulangerie_shop', tagName:'boulengerie', RequestTable:{}},
    fruiterie_shop : {communicTable:'05_fruiterie_shop', tagName:'fruiterie', RequestTable:{}},
    patisserie_shop : {communicTable:'05_patisserie_shop', tagName:'patisserie', RequestTable:{}},
    restaurant_commande : {communicTable:'05_restaurant_commande', tagName:'restaurant', RequestTable:{}},
    restaurant_reservation : {communicTable:'05_restaurant_reservation', tagName:'restaurant', RequestTable:{}},
    art_avis : {communicTable:'06_art_avis', tagName:'art', RequestTable:{}},
    cinema_reserver : {communicTable:'06_cinema_reserver', tagName:'cinema', RequestTable:{}},
    musee_reserver : {communicTable:'06_musee_reserver', tagName:'musee', RequestTable:{}},
    pyscine_souscrire : {communicTable:'06_pyscine_souscrire', tagName:'pyscine', RequestTable:{}},
    pyscine_reserver : {communicTable:'06_pyscine_reserver', tagName:'pyscine', RequestTable:{}},
    sport_salle_souscrire : {communicTable:'06_gym_souscription', tagName:'gym', RequestTable:{}},
    stade_reserver : {communicTable:'06_stade_reserver', tagName:'stade', RequestTable:{}},
    stade_souscrire : {communicTable:'06_stade_souscrire', tagName:'stade', RequestTable:{}},
    theatre_reserver : {communicTable:'06_theatre_reserver', tagName:'theatre', RequestTable:{}},
    agence_service : {communicTable:'07_agence_service', tagName:'vg_agence', RequestTable:{}},
    hotels_reserver : {communicTable:'07_hotels_reserver', tagName:'hotels', RequestTable:{}},
    hotels_service : {communicTable:'07_hotels_service', tagName:'hotels', RequestTable:{}},
    comptable_service : {communicTable:'08_comptable_service', tagName:'comptable', RequestTable:{}},
    socite_demande : {communicTable:'08_socite_demande', tagName:'socite', RequestTable:{}},
    socite_emploi : {communicTable:'08_socite_emploi', tagName:'socite', RequestTable:{}},
    depot_commande : {communicTable:'08_depot_commande', tagName:'depot', RequestTable:{}},
    depot_demande : {communicTable:'08_vente_en_gros_demande', tagName:'depot', RequestTable:{}},
    architecture_service : {communicTable:'09_architecture_service', tagName:'architecture', RequestTable:{}},
    contracteur_service : {communicTable:'09_contracteur_service', tagName:'contracteur', RequestTable:{}},
    courtier_request : {communicTable:'09_courtier_request', tagName:'courtier', RequestTable:{}},
    courtier_torent : {communicTable:'09_courtier_torent', tagName:'courtier', RequestTable:{}},
    handmade_service : {communicTable:'09_handmade_service', tagName:'handmade', RequestTable:{}},
    materiaux_vc_shop : {communicTable:'09_materiaux_vc_shop', tagName:'xxxxxxxxxx', RequestTable:{}},
    quincaillerie_shop : {communicTable:'09_quincaillerie_shop', tagName:'quincaillerie', RequestTable:{}},
    samsar_rent : {communicTable:'09_samsar_rent', tagName:'xxxxxxxxxx', RequestTable:{}},
    avocat_rdv : {communicTable:'10_avocat_rdv', tagName:'avocat', RequestTable:{}},
    avocat_souscrire : {communicTable:'10_avocat_souscrire', tagName:'avocat', RequestTable:{}},
    
    veterinaire_rdv : {communicTable:'11_veterinaire_rdv', tagName:'veterinaire', RequestTable:{}},
    fourragerie_shop : {communicTable:'11_fourragerie_shop', tagName:'fourragerie', RequestTable:{}},
    agritools_request : {communicTable:'11_agritools_request', tagName:'agritools', RequestTable:{}},
    
    message_contents : {communicTable:'message_contents', tagName:'xxxxxxxxxx', RequestTable:{}},
    message_conversations : {communicTable:'message_conversations', tagName:'xxxxxxxxxx', RequestTable:{}}
}
export default REQUESTS 