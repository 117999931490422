import React, {useEffect,useState}  from 'react';
import GConf from '../../AssetsM/generalConf';
import SubNav from '../Assets/subNav';
import { _ } from "gridjs-react";
import axios from 'axios';
import {Fade } from 'react-reveal';
import SKLT from '../../AssetsM/usedSlk'
import TableGrid from '../Assets/tableGrid';
import TableImage from '../Assets/tableImg';
import GoBtn from '../Assets/goBtn';
import { Button , Icon, Label} from 'semantic-ui-react';
import { NavLink, useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

function ToolsPage() {
    /*################[Variable]###############*/
    let  [clientList, setClientList] = useState([SKLT.TableSlt]); 
    let navigate = useNavigate();
    let Offline = JSON.parse(localStorage.getItem(`${GConf.PID}_Offline`));
    const colors = [
        'red',
        'orange',
        'yellow',
        'olive',
        'green',
        'teal',
        'blue',
        'violet',
        'purple',
        'pink',
        'brown',
        'grey',
        'black',
    ]

    /*#########################[UseEffect]##################################*/
    useEffect(() => {
    // axios.post(`${GConf.ApiLink}/fournisseur`, {
    //     PID : GConf.PID,
    //   })
    //   .then(function (response) {
    //     console.log(response.data)
    //      let testTable = []
    //       response.data.map( (getData) => testTable.push([
    //     _(<AvatarCard lettre={capitalizeFirstLetter(getData.Four_Name)} />),
    //      getData.Four_Code_Fiscale,
    //      getData.Four_Name,
    //      getData.Four_Phone,
    //      getData.Four_Adress,
    //      getData.Articles_Genre,
    //      getData.Jour_Periodique,
    //      _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/S/fs/info/${getData.Four_ID}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
    //     ],))
    //     setClientList(testTable)
    //   }).catch((error) => {
    //     if(error.request) {
    //       toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment des ancien Camion  </div></>, GConf.TostInternetGonf)   
    //       setClientList(Offline.camion)
    //     }
    //   });
    }, [])


    /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const  capitalizeFirstLetter = (string) =>{
        return (string.charAt(0).toUpperCase() + string.slice(1)).charAt(0);
    }
    /*################[Card]###############*/
     const AvatarCard = (props) =>{
            return(<>
                <Label size='massive' circular color={colors[Math.floor(Math.random() * 10)]} key={1}>
                    <h3>{props.lettre}</h3>
                </Label>
                
            </>)
    }

    const LinkCardCard = (props) =>{
        return(<>
            <div className='card card-body border-div mb-2 shadow-sm '>
                <NavLink to={`/S/ot/${props.link}`}>
                    <div className={`bi bi-${props.icon} bi-lg text-center `} style={{color: props.color}} ></div>
                    <h2 className='text-center text-secondary mt-1'>{props.name}</h2>
                </NavLink>
            </div> 
        </>)
}

    return ( 
        <>
            <Fade>
                <br />
                <div className='row'>
                    <div className='col-6 col-md-4'><LinkCardCard name='Taxi' icon='car-front' color='#f0f71b' link='taxi' /></div>
                    <div className='col-6 col-md-4'><LinkCardCard name='Louage' icon='truck' color='#3659cf' link='louage' /></div>
                    <div className='col-6 col-md-4'><LinkCardCard name='Renting' icon='house-heart-fill' color='#32294a' link='renting' /></div>
                    <div className='col-6 col-md-4'><LinkCardCard name='AgriTools' icon='minecart-loaded' color='#1ea312' link='agritools' /></div>
                    <div className='col-6 col-md-4'><LinkCardCard name='News' icon='newspaper' color='#8a8a8a' link='news' /></div>
                    <div className='col-6 col-md-4'><LinkCardCard name='Blog' icon='blockquote-left' color='#334e6b' link='blog' /></div>
                </div>
            </Fade>         
        </>);
}

export default ToolsPage;