import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GConf from '../../AssetsM/generalConf';
function LatestRquests() {

    /*#########################[Const]##################################*/
    
    const [rendredData, setRendredData] = useState([])
    const [loading , setLoading] = useState(false)


    /*#########################[useEffect]##################################*/ 
    useEffect(() => {
        axios.post(`${GConf.ApiLink}/request/latest`, {
            UID: 'genre'
          })
          .then(function (response) {
            setRendredData(response.data)
            console.log(response.data)
            setLoading(true)  
          }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger la commande   </div></>, GConf.TostInternetGonf)   
              setLoading(true) 
            }
          });
    }, [])

    //Card 
    const RenredItemCard = (props) => {
        return(<>
          <div className='card border-0 card-body   mb-4'>
                <h5 className='text-secondary mb-1'> {props.data.UserData.Name}  <span className='bi bi-caret-right-fill text-info'></span>  {props.data.PidData.Name} </h5>
                <small> {props.data.RequestData.R_Date} : {props.data.RequestData.R_Time} </small>
                <div className='ms-4 mt-2'> {props.data.P_Genre} : {props.data.Notif_Name} </div>
          </div> 
        </>)
    }


    return ( <>
            {
                rendredData.map((data,index) => <RenredItemCard key={index} data={data} />)
            }
    </> );
}

export default LatestRquests;